export function mailingConfig(shippingAddress, firstName, lastName) {
  return {
    firstName: {
      id: 'firstName',
      label: 'First Name',
      value: firstName || '',
    },
    lastName: {
      id: 'lastName',
      label: 'Last Name',
      value: lastName || '',
    },
    address1: {
      id: 'address1',
      label: 'Address',
      value: shippingAddress.street_1 || '',
    },
    address2: {
      id: 'address2',
      label: 'Address Continued',
      value: shippingAddress.street_2 || '--',
    },
    zipcode: {
      id: 'zipcode',
      label: 'Zip Code',
      value: shippingAddress.zipcode || '',
    },
    state: {
      id: 'state',
      label: 'State',
      value: shippingAddress.state_province || '',
    },
    city: {
      id: 'city',
      label: 'City',
      value: shippingAddress.city || '',
    },
  };
}

export function userConfig(user) {
  return {
    phone: {
      id: 'phone',
      label: 'Phone Number',
      value: user.phone || '',
    },
    email: {
      id: 'email',
      label: 'Email Address',
      value: user.email || '',
    },
  };
}
