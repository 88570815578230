import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ButtonStyles, SimpleHeader } from '@pumpkincare/shared/ui';

import { getMediaIsXsDown, Paths } from '../../../app-shell';

import styles from './direct-deposit-success.css';

function DirectDepositSuccess() {
  const isXsDown = useSelector(getMediaIsXsDown);

  const img = isXsDown
    ? '/assets/images/quote-flow-later-cat.png'
    : '/assets/images/photos/gray-cat-720x910.png';

  return (
    <div>
      <SimpleHeader returnLink={Paths.Claims} />

      <div className={styles.wrapper}>
        <div className={styles.headerDiv}>
          <h1 className={styles.header}>Your direct deposit info is saved!</h1>

          <Link to={Paths.Claims} className={styles.continueButton}>
            <button
              className={classNames(ButtonStyles.primary, styles.primaryButton)}
            >
              Continue to Your Pumpkin Account
            </button>
          </Link>

          <div className={styles.shapesDiv}>
            <div className={styles.heartDiv}>
              <img
                className={styles.redHeart}
                src={'/assets/images/heart-red.svg'}
                alt=''
                role='presentation'
              />
            </div>

            <div className={styles.boneDiv}>
              <img
                className={styles.blueBone}
                src={'/assets/images/blue-bone.svg'}
                alt='pet'
              />

              <img
                className={styles.yellowEllipse}
                src='/assets/images/ellipse-yellow.svg'
                alt='yellow ellipse'
              />
            </div>
          </div>
        </div>

        <div>
          <img className={styles.catImage} src={img} alt='cat' />
        </div>
      </div>
    </div>
  );
}

export default DirectDepositSuccess;
