import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import {
  formatCurrency,
  formatISODate,
  isCreditCardExpDateValid,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  LegalBody,
  Spinner,
  Typography,
} from '@pumpkincare/shared/ui';
import {
  formatBillingAddressText,
  getPaymentMethodBrand,
  getPaymentMethodExpMonth,
  getPaymentMethodExpYear,
  getPaymentMethodLast4,
  getUserBillingAddress,
  getUserIsChargedAnnually,
  getUserLapsedSince,
  getUserPets,
  POLICY_STATUS,
  useMonthlyStatements,
  usePayment,
  useUserAddresses,
  useUssr,
} from '@pumpkincare/user';

import MailingAddressUpdateModal from '../mailing-address-update-modal';
import BillingAddressUpdateModal from './billing-address-update-modal';
import EditPaymentModal from './edit-payment-modal';
import MonthlyStatementsModal from './monthly-statements-modal';

import sharedStyles from '../account-wrapper-shared.css';
import styles from './account-billing.css';

// known limitation is that we cannot differentiate if a user has two pets of the same name
export function isAPetLapsed(statementPets, userPets) {
  return statementPets.some(petName => {
    const pet = userPets.find(userPet => userPet.name === petName);

    return pet?.policies.some(policy => policy.status === POLICY_STATUS.LAPSED);
  });
}

function AccountBilling() {
  const { hash } = useLocation();

  const { data: userData, isFetching: isUserFetching } = useUssr();
  const pets = getUserPets(userData);
  const isUserLapsed = !!getUserLapsedSince(userData);
  const isChargedAnnually = getUserIsChargedAnnually(userData);

  const { data: addressData, isFetching: isBillingFetching } = useUserAddresses();
  const billingAddress = getUserBillingAddress(addressData);

  const { data: statementsData, isFetching: isStatementsFetching } =
    useMonthlyStatements();

  const { data: paymentData, isFetching: isPaymentFetching } = usePayment();
  const expMonth = getPaymentMethodExpMonth(paymentData);
  const expYear = getPaymentMethodExpYear(paymentData);
  const brand = getPaymentMethodBrand(paymentData);
  const last4 = getPaymentMethodLast4(paymentData);

  const [isPaymentModalOpen, togglePaymentModal] = useBooleanInput(
    hash === '#edit-payment-method'
  );
  const [isBillingAddressModalOpen, toggleBillingAddressModal] =
    useBooleanInput(false);
  const [isMailingAddressModalOpen, toggleMailingAddressModal] =
    useBooleanInput(false);
  const [statementsModalData, setStatementsModalData] = useState(null);

  function handleBillingUpdateSuccess() {
    toggleBillingAddressModal();
    toggleMailingAddressModal();
  }

  const isCreditCardInvalid =
    isUserLapsed && !isCreditCardExpDateValid(expMonth, expYear);

  return (
    <div className={styles.root}>
      <div className={sharedStyles.section}>
        <h5>Payment Method</h5>

        <div className={sharedStyles.cell}>
          {isPaymentFetching ? (
            <Spinner data-testid='payments-spinner' />
          ) : (
            <>
              <div className={sharedStyles.flex}>
                <div className={sharedStyles.topLine}>
                  <Body2 isBold>
                    {brand} **** {last4}
                  </Body2>

                  <button onClick={togglePaymentModal} className={Typography.action}>
                    Edit
                  </button>
                </div>

                <Body2
                  className={classNames({ [styles.error]: isCreditCardInvalid })}
                >
                  Expiration {expMonth}/{expYear}
                </Body2>

                {isCreditCardInvalid ? (
                  <LegalBody className={styles.error}>
                    Your credit card has expired. Please update your payment method
                    in order to process your past due payment.
                  </LegalBody>
                ) : null}

                {isPaymentModalOpen ? (
                  <EditPaymentModal handleCloseModal={togglePaymentModal} />
                ) : null}
              </div>
            </>
          )}
        </div>

        <div className={sharedStyles.cell}>
          {isBillingFetching ? (
            <Spinner data-testid='billing-spinner' />
          ) : (
            <>
              <div className={sharedStyles.flex}>
                <div className={sharedStyles.topLine}>
                  <Body2 isBold>Billing Address</Body2>

                  <button
                    onClick={toggleBillingAddressModal}
                    className={Typography.action}
                  >
                    Edit
                  </button>
                </div>

                <Body2>{formatBillingAddressText(billingAddress)}</Body2>
              </div>

              {isBillingAddressModalOpen ? (
                <BillingAddressUpdateModal
                  handleCloseModal={toggleBillingAddressModal}
                  billingAddress={{
                    street_1: billingAddress.street_1,
                    street_2: billingAddress.street_2,
                    city: billingAddress.city,
                    state: billingAddress.state_province,
                    zipcode: billingAddress.zipcode,
                    country: billingAddress.country,
                  }}
                  handleOnSuccess={handleBillingUpdateSuccess}
                />
              ) : null}

              {isMailingAddressModalOpen ? (
                <MailingAddressUpdateModal
                  handleCloseModal={toggleMailingAddressModal}
                  header="Is this your pet's new home address too?"
                />
              ) : null}
            </>
          )}
        </div>

        <div className={sharedStyles.cell}>
          <div className={sharedStyles.flex}>
            <div className={sharedStyles.topLine}>
              <Body2 isBold>Billing Cycle</Body2>
            </div>

            <Body2>
              {isChargedAnnually ? 'Annually' : 'Monthly'} (on the billing day of the
              policy)
            </Body2>
          </div>
        </div>
      </div>

      <div className={sharedStyles.section}>
        <h5>Upcoming Payments</h5>

        {isStatementsFetching ? (
          <Spinner classes={{ root: styles.margin }} />
        ) : (
          statementsData.map(statement => (
            <div key={statement.monthly_charge_day} className={sharedStyles.cell}>
              <div className={sharedStyles.flex}>
                <div className={sharedStyles.topLine}>
                  <Body2 isBold>{statement.pet_names.join(' / ')}</Body2>
                  {statement.balance &&
                  !isUserFetching &&
                  isAPetLapsed(statement.pet_names, pets) ? (
                    <LegalBody className={styles.lapsed}>Lapsed</LegalBody>
                  ) : null}
                </div>

                {statement.next_charge_date ? (
                  <Body2>
                    Next payment scheduled{' '}
                    <b>
                      {formatISODate(statement.next_charge_date, {
                        inputTimezone: 'local',
                        format: 'MMM D, YYYY',
                      })}
                    </b>
                  </Body2>
                ) : null}

                <Body2>
                  Current balance{' '}
                  {formatCurrency(statement.balance, { areCents: true })}
                </Body2>

                <button
                  className={classNames(
                    styles.monthlyStatement,
                    ButtonStyles.secondary
                  )}
                  onClick={() => setStatementsModalData(statement)}
                >
                  View monthly statements
                </button>
              </div>
            </div>
          ))
        )}

        {statementsModalData ? (
          <MonthlyStatementsModal
            statements={statementsModalData}
            handleCloseModal={() => setStatementsModalData(null)}
          />
        ) : null}
      </div>
    </div>
  );
}

export default AccountBilling;
