import {
  dateIsAfter,
  dateIsBefore,
  getCustomerAgreement,
} from '@pumpkincare/shared';
import {
  downloadPlanDoc,
  downloadPolicy,
  downloadUpdatedPolicy,
} from '@pumpkincare/user';

const INACTIVE_STATUSES = ['inactive', 'cancelled', 'expired'];
const ACTIVE_STATUSES = ['active', 'pending'];

export function downloadPolicyCa(policyId) {
  return downloadPolicy(policyId).then(url => {
    window.open(url, '_blank');
  });
}

export function downloadUpdatedPolicyCa(policyUpdatedId, documentId) {
  return downloadUpdatedPolicy(policyUpdatedId, documentId).then(url => {
    window.open(url, '_blank');
  });
}

// 3.1 and 4.0 agreement without summary. Agreement is species-agnostic
export function downloadPlanCa(petSpecies = 'dog', version = 4) {
  const preventCustomerAgreementPath = getCustomerAgreement(petSpecies, version);

  window.open(preventCustomerAgreementPath, '_blank');
}

export function downloadCustomPlanCa(petPlanId) {
  return downloadPlanDoc(petPlanId).then(url => {
    window.open(url, '_blank');
  });
}

export function getHasInactivePolicy(item) {
  return INACTIVE_STATUSES.some(status => status === item.status);
}

export function getHasActivePolicy(item) {
  return ACTIVE_STATUSES.some(status => status === item.status);
}

export function getPolicyForDate(policies, dateStr) {
  return policies.find(
    policy =>
      dateIsAfter(dateStr, policy.policy_effective_date) &&
      dateIsBefore(dateStr, policy.policy_end_date)
  );
}

export function getActivePolicy(policies) {
  const value = policies.find(policy => policy.status === 'active');

  return value !== undefined ? value : null;
}

function formatValues(utilized, total, percentage) {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  return {
    utilized: numberFormat.format(Math.trunc(utilized)),
    total: numberFormat.format(Math.trunc(total)),
    percentage: Math.round(percentage),
  };
}

export function getHasPolicyUtilization(policy) {
  return !!policy.utilization && policy.utilization.length > 0;
}

export function getDeductibleUtilization(utilization) {
  const deductible = utilization.find(item => item.entitlement === 'Deductible');
  if (!deductible) {
    return null;
  }

  const total = deductible.available + deductible.pending + deductible.utilized;

  const percentage = total > 0 ? (deductible.utilized / total) * 100 : 0;

  return formatValues(deductible.utilized / 100, total / 100, percentage);
}

export function getLimitUtilization(utilization) {
  const annualLimit = utilization.find(item => item.entitlement === 'Annual Limit');
  if (!annualLimit) {
    return null;
  }

  const total = annualLimit.available + annualLimit.pending + annualLimit.utilized;

  const percentage = total > 0 ? (annualLimit.utilized / total) * 100 : 0;

  return formatValues(annualLimit.utilized / 100, total / 100, percentage);
}
