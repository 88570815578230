import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { validateEmail } from '@pumpkincare/shared';
import { useBooleanInput, useTargetState } from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  LoaderButton,
  TextField,
} from '@pumpkincare/shared/ui';
import { getUserMailingAddress, useUserAddresses } from '@pumpkincare/user';

import { getUserSelector } from '../../../index';
import { changeEmail } from '../../../index';
import { mailingConfig, userConfig } from '../../shared';

import styles from './my-information.module.css';

function MyInformation() {
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const [isEditing, toggleIsEditing] = useBooleanInput(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newEmail, updateNewEmail] = useTargetState(user.email || '');

  const { data: addressData } = useUserAddresses();
  const mailingAddress = getUserMailingAddress(addressData);

  const mailingFieldsConfig =
    mailingAddress && mailingConfig(mailingAddress, user.firstName, user.lastName);

  const userFieldsConfig = userConfig(user);

  const onClickSaveChanges = e => {
    e.preventDefault();

    if (newEmail === '') {
      setErrorMessage('Email can not be blank');
      return;
    } else if (!validateEmail(newEmail)) {
      setErrorMessage('Not a valid email address');
      return;
    }

    setLoading(true);
    dispatch(changeEmail(newEmail))
      .then(() => {
        toggleIsEditing();
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        // todo Thunk this
        setErrorMessage(error.response.data.message);
        return;
      });
  };

  const onClickCancel = () => {
    toggleIsEditing();
    setErrorMessage('');
  };

  const MyInfoField = ({
    className = styles.halfFlex,
    readOnly = true,
    value = '',
    ...rest
  }) => (
    <div className={className}>
      <TextField
        readOnly={readOnly}
        value={value}
        classes={{ container: styles.textField }}
        {...rest}
      />
    </div>
  );

  function handleEmailChange(event) {
    const email = event.target.value.trim();
    updateNewEmail(email);
  }

  function handleEditEmailClick() {
    updateNewEmail(user.email.trim());
    setErrorMessage('');
    toggleIsEditing();
  }

  return (
    <div className={styles.root}>
      <h4 className={styles.sectionTitle}>{`My Information`}</h4>
      <div className={styles.root}>
        <Body2 className={styles.shippingTitle}>{`Mailing Address`}</Body2>
      </div>
      <div className={styles.fieldTable}>
        <MyInfoField {...mailingFieldsConfig?.firstName} />
        <MyInfoField {...mailingFieldsConfig?.lastName} />
        <MyInfoField {...mailingFieldsConfig?.address1} />
        <MyInfoField {...mailingFieldsConfig?.address2} />
        <MyInfoField {...mailingFieldsConfig?.zipcode} />
        <MyInfoField {...mailingFieldsConfig?.city} />
        <MyInfoField className={styles.fullFlex} {...mailingFieldsConfig?.state} />
        <MyInfoField className={styles.fullFlex} {...userFieldsConfig.phone} />
      </div>

      <div className={styles.root}>
        <Body2>{`Email Address`}</Body2>
      </div>

      {isEditing ? (
        <form onSubmit={onClickSaveChanges} noValidate>
          <div className={styles.emailValue}>
            <TextField
              type='email'
              label={userFieldsConfig.email.label}
              value={newEmail}
              onChange={handleEmailChange}
              error={{ errorMessage }}
              classes={{ container: styles.textField }}
            />
          </div>
          <LoaderButton
            color='primary'
            isLoading={loading}
            classes={{ root: styles.editingModeSaveButton }}
            type='submit'
          >
            Save Changes
          </LoaderButton>
          <button
            className={classNames(
              ButtonStyles.secondary,
              styles.editingModeCancelButton
            )}
            onClick={onClickCancel}
          >
            Cancel
          </button>
        </form>
      ) : (
        <div className={styles.emailContainer}>
          <div className={styles.emailInfo}>
            <TextField
              readOnly
              classes={{ container: styles.textField }}
              {...userFieldsConfig.email}
            />
          </div>
          {!isEditing && (
            <button
              className={classNames(ButtonStyles.secondary, styles.editButton)}
              onClick={handleEditEmailClick}
            >
              Edit
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default MyInformation;
