// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pet-claims_root_86a73046 {\n  margin-top: 48px;\n}\n\n.pet-claims_fileAClaim_5be106f7 {\n  padding-left: var(--w1);\n  padding-right: var(--w1);\n}", "",{"version":3,"sources":["webpack://src/user/view/pet-wrapper/pet-claims/pet-claims.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B","sourcesContent":["@value tablet, desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.root {\n  margin-top: 48px;\n}\n\n.fileAClaim {\n  padding-left: var(--w1);\n  padding-right: var(--w1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + "",
	"root": "pet-claims_root_86a73046",
	"fileAClaim": "pet-claims_fileAClaim_5be106f7"
};
export default ___CSS_LOADER_EXPORT___;
