import { createSelector } from 'reselect';

import { getActivePlan, parsePlanDetails } from '@pumpkincare/plans';

import { transformPetPolicy } from '../utils/transform-pet-policy';
import { formatPetAge } from '../utils/transform-pet-policy';
import {
  getUserIsChargedAnnually,
  getUserPetsSelector,
  getUserTransactionFee,
} from './user-selectors';

function getRatingStateFromProps(state, props) {
  return props;
}

const getPetPolicyConfig = createSelector(
  [
    getUserPetsSelector,
    getRatingStateFromProps,
    getUserIsChargedAnnually,
    getUserTransactionFee,
  ],
  (pets, ratingState, isAnnuallyCharged, transactionFee) => {
    return pets
      .filter(item => {
        return item.policies[0]?.policy_effective_date !== null;
      })
      .map(pet => {
        const transformedPetPolicy = transformPetPolicy(pet);
        const {
          policy,
          renewalPlan,
          renewalPolicy,
          renewalPolicyPrice,
          renewalPlanPrice,
        } = transformedPetPolicy;
        const activePlan = getActivePlan(pet.plans);
        const userHasPlan = !!activePlan;
        const isActivePetPolicyAvailable = policy && !!policy.policy_s3_path;
        const isRenewalPetPolicyAvailable =
          renewalPolicy && !!renewalPolicy.policy_s3_path;
        const renewalPlanDescription = renewalPlan && renewalPlan.plan.description;

        const multiplier = isAnnuallyCharged ? 12 : 1;
        const monthlyFee = transactionFee || 0;
        const insurancePrice = (renewalPolicyPrice || 0) * multiplier;
        const preventPrice = (renewalPlanPrice || 0) * multiplier;

        const preventLineItemsFormatted = renewalPlan
          ? parsePlanDetails(renewalPlan.plan, pet.species)
          : [];

        const subtotal = insurancePrice + (preventPrice || 0);
        const total = subtotal + monthlyFee / 100;

        transformedPetPolicy.renewalPolicyPrice = insurancePrice;
        transformedPetPolicy.renewalPlanPrice = preventPrice;
        transformedPetPolicy.renewalSubtotal = subtotal;
        transformedPetPolicy.renewalTotal = total;
        transformedPetPolicy.monthlyFee = monthlyFee;
        const summaryProductsText = `Pumpkin Pet Insurance${
          userHasPlan ? ` + Preventive Essentials` : ``
        }`;
        const summaryInfoText = `Age: ${formatPetAge(pet.age)} | Breed: ${
          pet.breed_name
        } | Location: ${ratingState}`;

        return {
          pet,
          ...transformedPetPolicy,
          userHasPlan,
          isActivePetPolicyAvailable,
          isRenewalPetPolicyAvailable,
          renewalPlanDescription,
          summaryProductsText,
          summaryInfoText,
          preventLineItemsFormatted,
        };
      });
  }
);

export default getPetPolicyConfig;
