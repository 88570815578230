import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getProductCanUpsellPrevent,
  hasCustomPlanDoc,
  useProductsByState,
} from '@pumpkincare/plans';
import { PET_AGE_OPTIONS } from '@pumpkincare/shared';
import { ChevronRight, Heart, LegalBody, Spinner } from '@pumpkincare/shared/ui';
import {
  getPlanDates,
  getPlanDocuments,
  getPlanEntitlements,
  getPlanPrice,
  getPolicyConfig,
  getPolicyDates,
  getPolicyDocuments,
  getPolicyPrice,
  getPolicyUtilization,
  getUserIsChargedAnnually,
  getUserRatingAddress,
  getUserTransactionFee,
  PetPlanOverview,
  PetPolicyOverview,
  useUserAddresses,
  useUssr,
} from '@pumpkincare/user';

import { MemberCallout } from '../../../../shared';
import getPetPolicyConfig from '../../../selectors/get-pet-policy-config';
import { downloadPlanCa, downloadPolicyCa } from '../../../utils';
import RenewalModal from '../../renewal-modal';

import styles from './pet-coverage.css';

function formatPetAge(age) {
  const ageLabel = PET_AGE_OPTIONS.find(option => option.value === age)?.label;

  return ageLabel.replace(/ old/, '').replace(/year/, 'Year');
}

function PetCoverage({ pet }) {
  const { data: userData } = useUssr();
  const isAnnuallyCharged = getUserIsChargedAnnually(userData);
  const transactionFee = getUserTransactionFee(userData);

  const { data: addressData } = useUserAddresses();
  const ratingData = getUserRatingAddress(addressData);

  const { data: productsByStateData, isFetching: isProductsFetching } =
    useProductsByState(ratingData.state_province);
  const canUpsellPrevent = getProductCanUpsellPrevent(productsByStateData);

  const configPets = useSelector(state =>
    getPetPolicyConfig(state, ratingData.state_province)
  );

  const [isRenewalModalOpen, setIsRenewalModalOpen] = useState(false);

  /*
   turn the first space in a breed name into a non-breaking space to help with responsiveness
   this works better than most responsiveness approaches (e.g controlling width of breed)
   */
  const items = [
    { title: 'Breed', copy: pet.breed_name.replace(/\s/, '&nbsp;') },
    {
      title: 'Age',
      copy: formatPetAge(pet.age),
    },
  ];

  const { cancelled, declined, renewal, waiting } = getPolicyConfig(pet.policies);

  /*
   TODO: look into refactoring RenewalModal to need less prop-drilling - blocked by not wanting to mess up redux / old member center
   dawgs2016MemberCenterRedesign included here to just pop this up when removing feature flag
  */
  function handleOnDownloadPlan(configPet) {
    const { renewalPlan } = configPet;

    if (hasCustomPlanDoc(renewalPlan.plan)) {
      downloadPlanCa();
    } else {
      downloadPlanCa(configPet.pet.species, renewalPlan.plan.version_number);
    }
  }

  function handleRenewalModalClose() {
    setIsRenewalModalOpen(false);
  }

  return (
    <div className={styles.root}>
      <MemberCallout type='pet' items={items} className={styles.callout}>
        {cancelled.isDeceased ? (
          <LegalBody className={styles.center}>
            In loving memory{' '}
            <Heart width={21} height={16} className={styles.heart} />
          </LegalBody>
        ) : null}

        {declined.isDeclined ? (
          <LegalBody>
            Plan cancels on <b>{declined.endDate}</b>
          </LegalBody>
        ) : null}

        {waiting.isWaiting ? (
          <LegalBody>
            {waiting.isModelLaw ? 'Illness ' : ''}Waiting Period until{' '}
            <b>{waiting.startDate}</b>
          </LegalBody>
        ) : null}

        {renewal.isRenewal ? (
          <>
            <LegalBody style={{ paddingTop: '4px' }}>
              Plan auto renews <b>{renewal.renewalDate}</b>
            </LegalBody>
            <button
              className={styles.renewal}
              onClick={() => setIsRenewalModalOpen(true)}
            >
              <LegalBody isBold>
                View Renewal Details <ChevronRight width={8} height={14} />
              </LegalBody>
            </button>
          </>
        ) : null}
      </MemberCallout>

      <PetPolicyOverview
        documents={getPolicyDocuments(pet.policies)}
        utilization={getPolicyUtilization(pet.policies)}
        dateConfig={getPolicyDates(pet.policies)}
        price={getPolicyPrice(pet.policies, isAnnuallyCharged)}
        classes={{ root: styles.policy }}
      />

      {pet.plans.length || canUpsellPrevent ? (
        <PetPlanOverview
          hasPlans={!!pet.plans.length}
          petName={pet.name}
          price={getPlanPrice(pet.plans, isAnnuallyCharged)}
          entitlements={getPlanEntitlements(pet.plans)}
          documents={getPlanDocuments(pet.plans)}
          dateConfig={getPlanDates(pet.plans)}
        />
      ) : isProductsFetching ? (
        <Spinner />
      ) : null}

      {isRenewalModalOpen ? (
        <RenewalModal
          isAnnuallyCharged={isAnnuallyCharged}
          configPets={configPets}
          onClose={handleRenewalModalClose}
          onDownloadPolicy={downloadPolicyCa}
          onDownloadPlan={handleOnDownloadPlan}
          transactionFee={transactionFee}
        />
      ) : null}
    </div>
  );
}

export default PetCoverage;
