// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_header_8db1a4c5 {\n  display: flex;\n  flex-direction: column;\n  margin-top: 50px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["downXs"] + " {\n  .header_header_8db1a4c5 {\n    margin-top: 30px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/user/view/shared/header/header.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":["@value downXs from '~@pumpkincare/shared/ui/breakpoints';\n\n.header {\n  display: flex;\n  flex-direction: column;\n  margin-top: 50px;\n}\n\n@media downXs {\n  .header {\n    margin-top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downXs": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["downXs"] + "",
	"header": "header_header_8db1a4c5"
};
export default ___CSS_LOADER_EXPORT___;
