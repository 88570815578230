import { useState } from 'react';
import { useQueryClient } from 'react-query';

import {
  Body1,
  Body2,
  FileUpload,
  LoaderButton,
  Modal,
} from '@pumpkincare/shared/ui';
import {
  getUserId,
  postMedicalHistory,
  uploadFile,
  USER_SELF_QUERY,
  useUssr,
} from '@pumpkincare/user';
import { VET_FORM_QUERY } from '@pumpkincare/vets';

import styles from './pet-medical-history-upload.css';

function PetMedicalHistoryUpload({ onClose, petId, petName, vetId }) {
  const queryClient = useQueryClient();
  const { data: userId } = useUssr(getUserId);

  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  function handleMedicalRecordsUpload() {
    const medicalRecords = vetId
      ? fileList.map(file => ({ ...file, vet_id: vetId }))
      : fileList;

    setIsUploading(true);

    postMedicalHistory(userId, petId, medicalRecords)
      .then(() => {
        queryClient.invalidateQueries([VET_FORM_QUERY]);
        queryClient.invalidateQueries([USER_SELF_QUERY]);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }

  return (
    <Modal
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <h4 id='modal-title' aria-level='1' className={styles.subheadText}>
        {`Add ${petName}'s Medical Records`}
      </h4>

      <Body1
        id='modal-description'
        role='heading'
        aria-level='2'
        className={styles.subHeaderText}
      >
        {`Help us process your future claims faster! Upload photos, scanned or digital copies of all medical history reports received from your vet.`}
      </Body1>

      <Body2 role='heading' aria-level='3' className={styles.contactInfo}>
        Don’t have them handy?
      </Body2>

      <Body1 role='heading' aria-level='4' className={styles.contactInfoText}>
        We will reach out to your vet to obtain your pet’s medical records.
      </Body1>

      <FileUpload
        files={fileList}
        onFileListChange={setFileList}
        onIsUploadingChange={setIsUploading}
        onUpload={(file, rawFile) => uploadFile(file, rawFile, 'medical-record')}
      />

      <LoaderButton
        color='primary'
        role='button'
        classes={{ root: styles.saveButton }}
        disabled={
          isUploading ||
          !fileList.length ||
          fileList.some(file => file.error !== undefined)
        }
        onClick={handleMedicalRecordsUpload}
        isLoading={isUploading}
      >
        Save Records
      </LoaderButton>
    </Modal>
  );
}

export default PetMedicalHistoryUpload;
