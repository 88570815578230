// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".monthly-statements-modal_body_8f99cdeb {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-billing/monthly-statements-modal/monthly-statements-modal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ","sourcesContent":[".body {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "monthly-statements-modal_body_8f99cdeb"
};
export default ___CSS_LOADER_EXPORT___;
