import ClaimsHowToFile from './claims-how-to-file';
import TrackClaims from './track-claims';

import styles from './pet-claims.css';

function PetClaims({ petId }) {
  return (
    <div className={styles.root}>
      <div className={styles.fileAClaim}>
        <ClaimsHowToFile petId={petId} />
      </div>
      <TrackClaims petId={petId} />
    </div>
  );
}

export default PetClaims;
