import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatCurrency, formatISODate, sortByTime } from '@pumpkincare/shared';
import { ButtonStyles, DocumentButton, Modal } from '@pumpkincare/shared/ui';

import modalStyles from '../../account-wrapper-modal-shared.css';
import styles from './monthly-statements-modal.css';

function MonthlyStatementsModal({ statements, handleCloseModal }) {
  return (
    <Modal
      onClose={handleCloseModal}
      classes={{
        container: modalStyles.modalContainer,
        content: modalStyles.modal,
      }}
      aria-label='Monthly Statements'
    >
      <h3>Monthly Statements</h3>
      <h5>Monthly Statements</h5>

      <div className={classNames(styles.body, modalStyles.modalBody)}>
        {sortByTime(statements.invoices).map(invoice => (
          <DocumentButton
            key={invoice.id}
            title={`${formatISODate(invoice.created_at, {
              inputTimezone: 'local',
              format: 'MMM D, YYYY',
            })} - ${invoice.status === 'paid' ? 'Paid' : 'Past Due'}`}
            subtitle={formatCurrency(invoice.total, { areCents: true })}
            href={invoice.receipt_url}
          />
        ))}

        <button
          onClick={handleCloseModal}
          className={classNames(
            modalStyles.modalButton,
            modalStyles.closeModalButton,
            ButtonStyles.baseButton
          )}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}

MonthlyStatementsModal.propTypes = {
  statements: PropTypes.shape({
    invoices: PropTypes.arrayOf(
      PropTypes.shape({
        created_at: PropTypes.string,
        id: PropTypes.string,
        receipt_url: PropTypes.string,
        status: PropTypes.string,
        total: PropTypes.number,
      })
    ),
    balance: PropTypes.number,
    monthly_charge_date: PropTypes.number,
    next_charge_date: PropTypes.string,
    pet_names: PropTypes.array,
  }).isRequired,

  handleCloseModal: PropTypes.func.isRequired,
};

export default MonthlyStatementsModal;
