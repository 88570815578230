import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  BannerType,
  COUNTRIES,
  COUNTRY_OPTIONS,
  getStripeError,
  postBillingAddressValidate,
  US_STATES,
  useBanners,
} from '@pumpkincare/shared';
import { useBooleanInput, useTargetState } from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  LegalBody,
  LoaderButton,
  Modal,
  Radio,
  RadioGroup,
  Select,
  StripeElementWrapper,
  TextField,
} from '@pumpkincare/shared/ui';
import {
  getUserBillingAddress,
  postChargeLapsedUser,
  useMutateUserBillingAddress,
  useMutateUserPaymentMethod,
  useUserAddresses,
} from '@pumpkincare/user';

import { getMediaIsXsDown } from '../../../app-shell';
import { getUserInvoices, getUserSelector } from '../../../user/selectors';
import {
  calculateAmountDue,
  calculateLapsedSince,
  formatAmountDue,
} from '../../../user/utils/invoices';
import { getBillingStripeError } from '../../selectors/billing-selectors';
import {
  setCreditCardUpdateError,
  setStripeError,
  setStripeToken,
  setUpdatedCreditCard,
} from '../../state/billing-ducks';

import styles from './edit-payment-modal.module.css';

const EMPTY = `empty`;
const COMPLETED = `completed`;
const MAPPED_STATES = Object.keys(US_STATES).map(item => ({
  value: item,
  label: item,
  name: 'state',
}));

export function formatBillingAddressText(address) {
  return (
    address?.street_1 +
    ', ' +
    (address?.street_2 ? address?.street_2 + ', ' : '') +
    address?.city +
    ', ' +
    address?.state_province +
    ', ' +
    address?.zipcode +
    ', ' +
    COUNTRIES.filter(country => country.id == address?.country)[0]?.name
  );
}

function EditPaymentModal({ handleCancel, handleSuccessClose }) {
  const stripe = useStripe();
  const elements = useElements();

  const { addBanner, removeAllBanners } = useBanners();

  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const stripeError = useSelector(getBillingStripeError);
  const invoicesList = useSelector(getUserInvoices);
  const [editedBillingAddress, setEditedBillingAddress] = useState({});
  const { mutateAsync: mutateUserPaymentMethod } = useMutateUserPaymentMethod();

  // card
  const [cardNumberStatus, setCardNumberStatus] = useState(EMPTY);
  const [expirationDateStatus, setExpirationDateStatus] = useState(EMPTY);
  const [cvcStatus, setCvcStatus] = useState(EMPTY);
  const [nameShownOnCard, setNameShownOnCard] = useTargetState('');
  const [nameShownOnCardStatus, setNameShownOnCardStatus] = useState(EMPTY);
  const [isAllCardInfoEmpty, setAllCardInfoEmpty] = useState(true);
  const [isAllCardInfoCompleted, setAllCardInfoCompleted] = useState(false);
  const isMobile = useSelector(getMediaIsXsDown);

  // button
  const [isEnabled, setEnabled] = useState(false);
  const [inProgress, showProgress] = useState(false);
  const formattedAmountDue = formatAmountDue(calculateAmountDue(user.invoices));

  const [isSameAddress, toggleIsSameAddress] = useBooleanInput(true);
  const [isValidZipCode, setIsValidZipCode] = useState(true);

  const formattedAmount = formatAmountDue(calculateAmountDue(invoicesList));
  const days = calculateLapsedSince(user.lapsedSince);
  const { data: addressData } = useUserAddresses();
  const billingAddress = getUserBillingAddress(addressData);
  const { mutateAsync: mutateAddress } = useMutateUserBillingAddress();

  useEffect(() => {
    if (Object.keys(billingAddress).length > 0) {
      setEditedBillingAddress({
        firstName: user?.firstName,
        lastName: user?.lastName,
        street_1: billingAddress?.street_1,
        street_2: billingAddress?.street_2,
        zipcode: billingAddress?.zipcode,
        city: billingAddress?.city,
        state: billingAddress?.state_province,
        country: billingAddress?.country,
      });
    }
  }, [billingAddress, user]);

  // This is used to check if all credit cards fields are empty or completed.
  // If all card fields are empty, we don't have to submit a card update.
  // If any card fields is completed, all other fields must be completed too, so we can submit a card update.

  useEffect(() => {
    let allEmpty =
      cardNumberStatus === EMPTY &&
      expirationDateStatus === EMPTY &&
      cvcStatus === EMPTY &&
      nameShownOnCardStatus === EMPTY;

    let allCompleted =
      cardNumberStatus === COMPLETED &&
      expirationDateStatus === COMPLETED &&
      cvcStatus === COMPLETED &&
      nameShownOnCardStatus === COMPLETED;

    setAllCardInfoEmpty(allEmpty);
    setAllCardInfoCompleted(allCompleted);
  }, [cardNumberStatus, expirationDateStatus, cvcStatus, nameShownOnCardStatus]);

  // Submit button is enabled and credit card fields are empty by default.
  // If the customer starts to fill some credit card field, we must disable the submit button.
  // The button will be re-enabled only if customer clear all or complete all fields.
  useEffect(() => {
    setEnabled((isAllCardInfoEmpty || isAllCardInfoCompleted) && !inProgress);
  }, [inProgress, isAllCardInfoEmpty, isAllCardInfoCompleted]);

  function handleStripeFieldsEmptyCheck(id, isEmpty, isComplete) {
    let status = EMPTY;

    if (!isEmpty) {
      if (isComplete) {
        status = COMPLETED;
      } else {
        status = 'invalid';
      }
    }

    switch (id) {
      case 'cardNumber': {
        setCardNumberStatus(status);
        break;
      }
      case 'expirationDate': {
        setExpirationDateStatus(status);
        break;
      }
      case 'cvc': {
        setCvcStatus(status);
        break;
      }
      default:
        break;
    }
  }

  function handleStripeError(error) {
    dispatch(setStripeError(error));
  }

  function onCardNameFieldChange(e) {
    setNameShownOnCard(e);

    if (e.target.value === '' || e.target.value == null) {
      setNameShownOnCardStatus(EMPTY);
    } else {
      setNameShownOnCardStatus(COMPLETED);
    }
  }

  function validateCardInfo() {
    if (!isAllCardInfoCompleted) {
      return false;
    } else if (stripeError) {
      setStripeToken(null);
      return false;
    }
    return true;
  }

  function submitUpdateCreditCard() {
    removeAllBanners();

    if (validateCardInfo()) {
      if (stripe) {
        stripe
          .createToken(elements.getElement(CardNumberElement))
          .then(stripeObject => {
            if (stripeObject.error) {
              setStripeError(stripeObject.error.message);
              setStripeToken(false);
            } else {
              const payload = {
                brand: stripeObject.token.card.brand,
                expMonth: stripeObject.token.card.exp_month,
                expYear: stripeObject.token.card.exp_year,
                last4: stripeObject.token.card.last4,
                stripeToken: stripeObject.token.id,
              };

              mutateUserPaymentMethod(payload)
                .then(() => {
                  dispatch(
                    setUpdatedCreditCard({
                      fetching: false,
                      updated: true,
                      error: false,
                    })
                  );

                  postChargeLapsedUser(user.id).then(() => {
                    showProgress(false);

                    if (!user.lapsedSince) {
                      addBanner({
                        type: BannerType.INFO,
                        title: 'Thank you for updating your payment method',
                      });
                    }

                    handleSuccessClose();
                  });
                })
                .catch(error => {
                  const stripeErrMsg = getStripeError(error);

                  dispatch(setCreditCardUpdateError(stripeErrMsg));

                  addBanner({
                    type: BannerType.ERROR,
                    title: stripeErrMsg,
                  });

                  showProgress(false);
                  handleCancel();
                });
            }
          })
          .catch(() => {
            addBanner({
              type: BannerType.ERROR,
              title: 'Fail to create stripe token',
            });

            handleCancel();
          });
      }
    } else {
      showProgress(false);

      addBanner({
        type: BannerType.INFO,
        title: 'Thank you for updating your billing address',
      });

      handleSuccessClose();
    }
  }

  function submit() {
    showProgress(true);

    postBillingAddressValidate(editedBillingAddress)
      .then(() => setIsValidZipCode(true))
      .catch(() => setIsValidZipCode(false))
      .then(() => mutateAddress({ address: editedBillingAddress }))
      .then(() => {
        submitUpdateCreditCard();
      })
      .catch(error => {
        showProgress(false);
        addBanner({
          type: BannerType.ERROR,
          message: error.body,
          title: 'An error happened while updating the billing address',
        });
      });
  }

  function handleAddressFieldChange(e) {
    const name = e.name || e.target.name;
    const value = e.value || e.target.value;

    setEditedBillingAddress(state => ({ ...state, [name]: value }));
  }

  return (
    <Modal onClose={handleCancel} classes={{ content: styles.modalContainer }}>
      <h1>Edit Payment Method</h1>

      {user.lapsedSince ? (
        <div className={styles.lapsedContainer}>
          <Body2 className={styles.lapsedText}>
            There’s an issue with your payment details. Update it below to resolve
            your past due balance.
          </Body2>

          <div className={styles.lapsedDetailGrid}>
            <Body2 className={styles.dueTodaySubhead}>Due Today:</Body2>
            <Body1 className={styles.lapsedText}>{formattedAmount}</Body1>
          </div>

          <Body1>
            Payment is {days} day{days > 1 ? 's' : ''} past due
          </Body1>
        </div>
      ) : null}

      <Body2>Card Details</Body2>
      <Body1>
        Pre-paid credit cards are not accepted. Credit and Debit cards only.
      </Body1>

      <div className={styles.paymentInputRoot}>
        <div className={styles.grid}>
          <div className={styles.gridItem}>
            <StripeElementWrapper
              label={'Debit or Credit Card Number'}
              id={'cardNumber'}
              component={CardNumberElement}
              onChange={handleStripeFieldsEmptyCheck}
              onError={handleStripeError}
              classes={{ root: styles.paymentField }}
            />
          </div>

          <div className={styles.gridItem}>
            <StripeElementWrapper
              label={'Expiration Date'}
              id={'expirationDate'}
              component={CardExpiryElement}
              onChange={handleStripeFieldsEmptyCheck}
              onError={handleStripeError}
              classes={{ root: styles.paymentField }}
            />
          </div>

          <div className={styles.gridItem}>
            <TextField
              label='Name Shown on Card'
              value={nameShownOnCard}
              classes={{
                container: styles.paymentField,
                fieldContainer: styles.nameMargin,
              }}
              onChange={onCardNameFieldChange}
            />
          </div>

          <div className={styles.gridItem}>
            <StripeElementWrapper
              label={'CVC Code'}
              id={'cvc'}
              component={CardCvcElement}
              onChange={handleStripeFieldsEmptyCheck}
              onError={handleStripeError}
              classes={{ root: styles.paymentField }}
            />
          </div>
        </div>

        <div className={styles.billingAddressTitleGrid}>
          <Body2>Billing Address</Body2>
          <Body1>Update billing address associated with your card.</Body1>
        </div>

        <RadioGroup
          name={`billingTheSame`}
          value={isSameAddress}
          onChange={toggleIsSameAddress}
          classes={{ root: styles.billingAddressRadioGroup }}
        >
          <Radio
            value={true}
            label={
              <div>
                <Body2>Same billing address</Body2>
                <LegalBody className={styles.radioBillingAddress}>
                  {formatBillingAddressText(billingAddress)}
                </LegalBody>
              </div>
            }
            classes={{
              radio: styles.billingRadio,
              root: styles.billingRadioControlLabel,
            }}
          />

          <Radio
            value={false}
            label={<Body2>New billing address</Body2>}
            classes={{ radio: styles.billingRadio }}
          />
        </RadioGroup>

        {isSameAddress === false ? (
          <div className={styles.container}>
            <div className={styles.gridItem}>
              <TextField
                id='firstName'
                name='firstName'
                label='First Name'
                value={editedBillingAddress?.firstName || ''}
                classes={{ container: styles.billingField }}
                readOnly
              />
            </div>

            <div className={styles.gridItem}>
              <TextField
                id='lastName'
                name='lastName'
                label='Last Name'
                value={editedBillingAddress?.lastName || ''}
                classes={{ container: styles.billingField }}
                readOnly
              />
            </div>

            <div className={styles.gridItem}>
              <TextField
                id='street_1'
                name='street_1'
                label='Address'
                value={editedBillingAddress?.street_1 || ''}
                classes={{ container: styles.billingField }}
                onChange={handleAddressFieldChange}
              />
            </div>

            <div className={styles.gridItem}>
              <TextField
                id='street_2'
                name='street_2'
                label='Address Continued'
                value={editedBillingAddress?.street_2 || ''}
                classes={{ container: styles.billingField }}
                onChange={handleAddressFieldChange}
              />
            </div>

            <div className={styles.gridItem}>
              <TextField
                id='zipcode'
                name='zipcode'
                label='Zip Code'
                error={{
                  errorMessage: isValidZipCode ? '' : 'Invalid Zip code',
                }}
                value={editedBillingAddress?.zipcode || ''}
                classes={{ container: styles.billingField }}
                onChange={handleAddressFieldChange}
              />
            </div>

            <div className={styles.gridItem}>
              <TextField
                id='city'
                name='city'
                label='City'
                value={editedBillingAddress?.city || ''}
                classes={{ container: styles.billingField }}
                onChange={handleAddressFieldChange}
              />
            </div>
            <div className={styles.gridItem}>
              <div className={styles.stateDropdown}>
                <Select
                  options={COUNTRY_OPTIONS}
                  onChange={e =>
                    handleAddressFieldChange({
                      value: e.value,
                      name: 'country',
                    })
                  }
                  label='Country'
                  placeholder='Country'
                  defaultValue={{
                    label: editedBillingAddress?.country || COUNTRY_OPTIONS[0].label,
                  }}
                  isMobile={isMobile}
                  menuPosition='fixed'
                />
              </div>
            </div>

            {editedBillingAddress?.country === 'US' ? (
              <div className={styles.gridItem}>
                <div className={styles.stateDropdown}>
                  <Select
                    options={MAPPED_STATES}
                    onChange={handleAddressFieldChange}
                    label='State'
                    placeholder='State'
                    defaultValue={{
                      label: editedBillingAddress?.state,
                    }}
                    isMobile={isMobile}
                    menuPosition='fixed'
                  />
                </div>
              </div>
            ) : (
              <div className={styles.gridItem}>
                <TextField
                  id='state'
                  name='state'
                  label='State'
                  value={editedBillingAddress?.state}
                  classes={{ container: styles.billingField }}
                  onChange={handleAddressFieldChange}
                />
              </div>
            )}
          </div>
        ) : null}

        <div className={styles.container}>
          <div className={styles.buttonRow}>
            <button
              className={classNames(ButtonStyles.secondary, styles.cancelButton)}
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>

          <div className={styles.buttonRow}>
            <LoaderButton
              color='primary'
              onClick={submit}
              disabled={!isEnabled}
              classes={{ root: styles.submitButton }}
              isLoading={inProgress}
            >
              {user.lapsedSince
                ? `Update & Pay Balance - ${formattedAmountDue}`
                : `Update & Save`}
            </LoaderButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

EditPaymentModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSuccessClose: PropTypes.func.isRequired,
};

export default EditPaymentModal;
