import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { hasCustomPlanDoc } from '@pumpkincare/plans';
import {
  ARF_MEOW_PHONE_NUMBER,
  CONTACT_EMAIL,
  FORMATTED_PHONE_NUMBER,
} from '@pumpkincare/shared';
import { Body2, LegalBody, Spinner, Typography } from '@pumpkincare/shared/ui';
import { getUserRatingAddress, useUserAddresses } from '@pumpkincare/user';

import getPetPolicyConfig from '../../selectors/get-pet-policy-config';
import {
  getUserExternalIdSelector,
  getUserPetsSelector,
} from '../../selectors/user-selectors';
import {
  downloadCustomPlanCa,
  downloadPlanCa,
  downloadPolicyCa,
  getHasInactivePolicy,
} from '../../utils/policy-utils';
import { transformPolicy } from '../../utils/transform-pet-policy';
import PetPolicy from '../pet-policy';
import { Header } from '../shared';

import styles from './pet-policy-wrapper.css';

function PetPolicyWrapper() {
  const externalId = useSelector(getUserExternalIdSelector);
  const pets = useSelector(getUserPetsSelector) || [];
  const [loading, setLoading] = useState(false);

  const { data: addressData } = useUserAddresses();
  const ratingAddress = getUserRatingAddress(addressData);

  const configPets = useSelector(state =>
    getPetPolicyConfig(state, ratingAddress.state_province)
  );
  const inactivePolicies = [];

  function handleOnClick(pet) {
    const { species, userPlan } = pet;

    if (hasCustomPlanDoc(userPlan.plan)) {
      setLoading(true);
      downloadCustomPlanCa(userPlan.id).then(() => setLoading(false));
    } else {
      downloadPlanCa(species, userPlan.plan.version_number);
    }
  }

  pets.forEach(pet => {
    pet.policies.forEach(policy => {
      if (getHasInactivePolicy(policy)) {
        inactivePolicies.push(transformPolicy(pet, policy));
      }
    });
  });

  return (
    <div className={styles.root}>
      <div className={styles.activeContainer}>
        <div className={styles.tabContentContainer}>
          <Header>Plan Details</Header>
          <div className={styles.PKNContainer}>
            <h4
              style={{ paddingRight: '6px', marginTop: '0px', marginBottom: '0px' }}
            >
              Account Number:{' '}
            </h4>
            <h4
              data-testid='account-number-value'
              style={{
                color: 'var(--gromitGray)',
                marginTop: '0px',
                marginBottom: '0px',
              }}
            >
              {' '}
              {externalId}{' '}
            </h4>
          </div>

          <PetPolicy configPets={configPets} />
        </div>

        <div className={styles.helpDiv}>
          <h4 className={styles.helpTitle}>
            Have questions or need to make changes?
          </h4>
          <Body2 className={styles.helpSubText}>
            Our care team is just a paw away.
          </Body2>
          <LegalBody>
            {ARF_MEOW_PHONE_NUMBER} ({FORMATTED_PHONE_NUMBER})
          </LegalBody>
          <LegalBody>{CONTACT_EMAIL}</LegalBody>
        </div>
      </div>
      {inactivePolicies.length > 0 && (
        <div>
          <h3>Inactive Plans</h3>
          <LegalBody className={styles.inactiveDiv}>
            Below is a summary of your previous or canceled plans.
          </LegalBody>
          <div className={styles.hiddentabletDown}>
            <div>
              <table className={classNames(Typography.body2, styles.table)}>
                <tbody className={styles.tableBody}>
                  {inactivePolicies.map(row => (
                    <tr className={styles.tableRow} key={row.id}>
                      <td
                        className={classNames(
                          Typography.legalBody,
                          styles.tableCell
                        )}
                      >
                        {row.period}
                      </td>
                      <td
                        className={classNames(
                          Typography.legalBody,
                          styles.tableCell
                        )}
                      >{`${row.petName}'s Plan`}</td>
                      <td
                        className={classNames(
                          Typography.legalBody,
                          styles.tableCell
                        )}
                      >
                        {row.policyPrice}
                      </td>
                      <td
                        className={classNames(
                          Typography.legalBody,
                          styles.tableCell
                        )}
                      >
                        {row.planName}
                      </td>
                      <td
                        className={classNames(
                          Typography.legalBody,
                          styles.tableCell
                        )}
                      >
                        {row.policyStatus}
                      </td>
                      <td
                        className={classNames(
                          Typography.legalBody,
                          styles.tableCell
                        )}
                      >
                        {row.userPlan && (
                          <Body2
                            onClick={() => handleOnClick(row)}
                            className={styles.downloadPolicy}
                          >
                            {loading ? (
                              <Spinner size={45} />
                            ) : (
                              'Download Customer Agreement'
                            )}
                          </Body2>
                        )}
                        {row.policy_s3_path && (
                          <Body2
                            onClick={() => downloadPolicyCa(row.id)}
                            className={styles.downloadPolicy}
                          >
                            Download Insurance Policy
                          </Body2>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.hiddentabletUp}>
            {inactivePolicies.map(row => (
              <div className={styles.inactivePoliciesMobile} key={row.id}>
                <LegalBody>{row.period}</LegalBody>
                <LegalBody>{`${row.petName}'s Plan`}</LegalBody>
                <LegalBody>{row.policyPrice}</LegalBody>
                <LegalBody>{row.planName}</LegalBody>
                <LegalBody>{row.policyStatus}</LegalBody>
                {row.userPlan && (
                  <div
                    onClick={() => handleOnClick(row)}
                    className={styles.downloadPolicy}
                  >
                    {loading ? <Spinner size={12} /> : 'Download Customer Agreement'}
                  </div>
                )}
                {row.policy_s3_path && (
                  <div
                    onClick={() => downloadPolicyCa(row.id)}
                    className={styles.downloadPolicy}
                  >
                    Download Insurance Policy
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PetPolicyWrapper;
