import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import { CHECKING, GENERIC_CONTACT_ERROR } from '@pumpkincare/shared';
import { useTargetState } from '@pumpkincare/shared';
import {
  ButtonStyles,
  LegalBody,
  LoaderButton,
  Modal,
  TextField,
} from '@pumpkincare/shared/ui';
import { useDeleteUserAch, useMutateUserAch, usePayment } from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import validateDirectDepositFields from '../../utils/validate-direct-deposit-fields';

import styles from './direct-deposit-setup-modal.css';

// To test on non-prod environments, routing numbers MUST BE 222222226 according to Dwolla
function DirectDepositSetupModal({ onCancel }) {
  const dispatch = useDispatch();

  const { isError: isPaymentError } = usePayment();
  const { mutateAsync: mutateUserAch, isLoading: isMutatingUserAch } =
    useMutateUserAch();
  const { isLoading: isDeletingUserAch } = useDeleteUserAch();
  const isLoading = isMutatingUserAch || isDeletingUserAch || isLoading;

  const [errorMessage, setErrorMessage] = useState('');
  const [accountHolderName, handleAccountHolderNameChange] = useTargetState('');
  const [accountNumber, handleAccountNumberChange] = useTargetState('');
  const [accountConfirmation, handleAccountConfirmationChange] = useTargetState('');
  const [routingNumber, handleRoutingNumberChange] = useTargetState('');
  const [routingConfirmation, handleRoutingConfirmationChange] = useTargetState('');
  const [errors, setErrors] = useState({});

  const accountInputLabel = `Checking account number`;
  const accountConfirmationInputLabel = `Confirm checking account number`;

  function onSubmit(e) {
    e.preventDefault();

    setErrorMessage('');

    const errorsValidation = validateDirectDepositFields(
      accountHolderName,
      accountNumber,
      accountConfirmation,
      routingNumber,
      routingConfirmation
    );

    if (errorsValidation.hasErrors) {
      setErrors(errorsValidation);
    } else {
      setErrors({});

      mutateUserAch({
        body: {
          name_on_account: accountHolderName,
          account_number: accountNumber,
          account_type: CHECKING,
          routing_number: routingNumber,
        },
      })
        .then(() => {
          onCancel();
          dispatch(push(Paths.Reimbursement));
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            if (
              error.response.data.error_type === 'account' ||
              error.response.data.error_type === 'unknown'
            ) {
              setErrorMessage(error.response.data.message);
            }
          } else {
            setErrorMessage(GENERIC_CONTACT_ERROR);
          }
        });
    }
  }

  function handleRoutingNumberFocus() {
    setErrors({
      ...errors,
      routingNumber: false,
    });
  }

  function handleAccountNumberFocus() {
    setErrors({
      ...errors,
      accountNumber: false,
    });
  }

  return (
    <Modal
      onClose={onCancel}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      classes={{ content: styles.directDepositContainer }}
    >
      <h3 className={styles.title}>Add bank account</h3>

      <LegalBody className={styles.bodyInfo}>
        Your claim reimbursements will be deposited directly into this account. To
        verify, you may receive small amounts deposited temporarily.
      </LegalBody>

      <form onSubmit={onSubmit} aria-label='directDepositForm'>
        <div className={styles.inputsContainer}>
          <TextField
            label='Account holder full name'
            onChange={handleAccountHolderNameChange}
            defaultValue={accountHolderName}
            classes={{ container: styles.textField, label: styles.label }}
            error={{ hasError: errors.accountHolderName }}
            required
          />

          <TextField
            label={accountInputLabel}
            maxLength={20}
            onChange={handleAccountNumberChange}
            defaultValue={accountNumber}
            classes={{ container: styles.textField, label: styles.label }}
            onFocus={handleAccountNumberFocus}
            error={{ hasError: errors.accountNumber }}
            required
          />

          <TextField
            label={accountConfirmationInputLabel}
            maxLength={20}
            onChange={handleAccountConfirmationChange}
            defaultValue={accountConfirmation}
            classes={{ container: styles.textField, label: styles.label }}
            error={{
              errorMessage: errors.accountConfirmation
                ? `Account numbers don't match.`
                : '',
            }}
            required
          />

          <TextField
            label={'Routing number'}
            placeholder={'Must be 9 digits long'}
            maxLength={9}
            onChange={handleRoutingNumberChange}
            defaultValue={routingNumber}
            classes={{ container: styles.textField, label: styles.label }}
            onFocus={handleRoutingNumberFocus}
            error={{
              errorMessage:
                errors.routingNumber || isPaymentError
                  ? (errors.routingNumber &&
                      !errors.routingConfirmation &&
                      'Please enter a 9 digit routing number') ||
                    errorMessage
                  : '',
              hasError: errors.routingNumber,
            }}
            required
          />

          <TextField
            label={'Confirm routing Number'}
            maxLength={9}
            onChange={handleRoutingConfirmationChange}
            defaultValue={routingConfirmation}
            classes={{ container: styles.textField, label: styles.label }}
            error={{
              errorMessage: errors.routingConfirmation
                ? `Routing numbers don't match.`
                : '',
            }}
            required
          />
        </div>

        <div className={styles.buttonsContainer}>
          <LegalBody className={styles.errorMessage}>{errorMessage}</LegalBody>
          <LoaderButton
            color='primary'
            type='submit'
            isLoading={isLoading}
            disabled={isPaymentError}
            classes={{ root: ButtonStyles.primary }}
          >
            Set up direct deposit
          </LoaderButton>

          <a role='button' className={styles.cancelButton} onClick={onCancel}>
            Cancel
          </a>
        </div>
      </form>
    </Modal>
  );
}

DirectDepositSetupModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default DirectDepositSetupModal;
