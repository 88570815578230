import { useState } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  getStripeError,
  useBooleanInput,
  useTargetState,
} from '@pumpkincare/shared';
import {
  ButtonStyles,
  LegalBody,
  LoaderButton,
  Modal,
  StripeElementWrapper,
  TextField,
} from '@pumpkincare/shared/ui';
import {
  getUserId,
  postChargeLapsedUser,
  useMutateUserPaymentMethod,
  useUssr,
} from '@pumpkincare/user';

import modalStyles from '../../account-wrapper-modal-shared.css';
import styles from './edit-payment-modal.css';

const EMPTY = `empty`;
const COMPLETED = `completed`;

function EditPaymentModal({ handleCloseModal }) {
  const stripe = useStripe();
  const elements = useElements();

  const { mutateAsync: mutateUserPaymentMethod } = useMutateUserPaymentMethod();
  const { data: userId } = useUssr(getUserId);

  const [cardNumberStatus, setCardNumberStatus] = useState(EMPTY);
  const [expirationDateStatus, setExpirationDateStatus] = useState(EMPTY);
  const [cvcStatus, setCvcStatus] = useState(EMPTY);
  const [nameShownOnCard, setNameShownOnCard] = useTargetState('');
  const [isSubmitting, toggleIsSubmitting] = useBooleanInput(false);
  const [inputStripeError, setInputStripeError] = useState(false);
  const [formStripeError, setFormStripeError] = useState('');

  function handleStripeFieldsEmptyCheck(id, isEmpty, isComplete) {
    const status = isEmpty ? EMPTY : isComplete ? COMPLETED : 'invalid';

    switch (id) {
      case 'cardNumber': {
        setCardNumberStatus(status);
        break;
      }
      case 'expirationDate': {
        setExpirationDateStatus(status);
        break;
      }
      case 'cvc': {
        setCvcStatus(status);
        break;
      }
      default:
        break;
    }
  }

  function handleStripeError(error) {
    setInputStripeError(error);
  }

  function onCardNameFieldChange(e) {
    setNameShownOnCard(e);
  }

  function isValidCardInfo() {
    return !(
      inputStripeError ||
      !nameShownOnCard ||
      [cardNumberStatus, expirationDateStatus, cvcStatus].some(
        status => status !== COMPLETED
      )
    );
  }

  function handleSubmitEditedPayment(e) {
    e.preventDefault();
    setFormStripeError('');

    if (!isValidCardInfo()) {
      setFormStripeError('Please fill out all fields');
      return;
    }

    if (stripe) {
      toggleIsSubmitting(true);
      stripe
        .createToken(elements.getElement(CardNumberElement))
        .then(stripeObject => {
          if (stripeObject.error) {
            setFormStripeError(stripeObject.error.message);
            toggleIsSubmitting();
          } else {
            const payload = {
              brand: stripeObject.token.card.brand,
              expMonth: stripeObject.token.card.exp_month,
              expYear: stripeObject.token.card.exp_year,
              last4: stripeObject.token.card.last4,
              stripeToken: stripeObject.token.id,
            };

            mutateUserPaymentMethod(payload)
              .then(() => {
                postChargeLapsedUser(userId)
                  .then(() => {
                    toggleIsSubmitting();
                    handleCloseModal();
                  })
                  .catch(() => {
                    setFormStripeError(
                      'Your payment method updated, but your account may have some unpaid lapsed invoices. Please contact support.'
                    );
                    toggleIsSubmitting();
                  });
              })
              .catch(error => {
                setFormStripeError(getStripeError(error));
                toggleIsSubmitting();
              });
          }
        })
        .catch(() => {
          setFormStripeError('Failed to create a stripe token');
          toggleIsSubmitting();
        });
    }
  }

  return (
    <Modal
      onClose={handleCloseModal}
      aria-label='Edit payment method'
      classes={{
        container: modalStyles.modalContainer,
        content: modalStyles.modal,
      }}
    >
      <h3>Edit payment method</h3>
      <h5>Edit payment method</h5>

      <form
        className={classNames(styles.form, modalStyles.modalBody)}
        onSubmit={handleSubmitEditedPayment}
      >
        <TextField
          label='Full name on card'
          id='full-name'
          value={nameShownOnCard}
          onChange={onCardNameFieldChange}
        />

        <StripeElementWrapper
          label={'Debit or credit card number'}
          id={'cardNumber'}
          component={CardNumberElement}
          onChange={handleStripeFieldsEmptyCheck}
          onError={handleStripeError}
        />

        <StripeElementWrapper
          label={'Expiration'}
          id={'expirationDate'}
          component={CardExpiryElement}
          onChange={handleStripeFieldsEmptyCheck}
          onError={handleStripeError}
          classes={{ root: styles.halfWidth }}
        />

        <StripeElementWrapper
          label={'CVC code'}
          id={'cvc'}
          component={CardCvcElement}
          onChange={handleStripeFieldsEmptyCheck}
          onError={handleStripeError}
          classes={{ root: styles.halfWidth }}
        />

        {formStripeError ? (
          <LegalBody className={styles.error}>{formStripeError}</LegalBody>
        ) : null}

        <LoaderButton
          type='submit'
          color='primary'
          isLoading={isSubmitting}
          classes={{ root: modalStyles.modalButton }}
        >
          Update payment method
        </LoaderButton>
      </form>

      <button
        onClick={handleCloseModal}
        className={classNames(
          modalStyles.modalButton,
          modalStyles.closeModalButton,
          ButtonStyles.baseButton
        )}
      >
        Cancel
      </button>
    </Modal>
  );
}

EditPaymentModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

export default EditPaymentModal;
