import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Store from 'store';

import { analyticsTrack, renewalsModalClose } from '@pumpkincare/analytics';
import { hasCustomPlanDoc } from '@pumpkincare/plans';
import { RENEWAL_MODAL_SHOWN_PETS } from '@pumpkincare/shared';
import {
  getUserIsChargedAnnually,
  getUserRatingAddress,
  getUserTransactionFee,
  useUserAddresses,
  useUssr,
} from '@pumpkincare/user';

import getPetPolicyConfig from '../../selectors/get-pet-policy-config';
import { downloadPlanCa, downloadPolicyCa } from '../../utils';
import Paperless from '../paperless';
import RenewalModal from '../renewal-modal';
import { Divider, Header } from '../shared';
import MyInformation from './my-information';
import MyPassword from './my-password';
import MyPetInformation from './my-pet-information';

import styles from './profile.module.css';

function Profile() {
  const { data: addressData } = useUserAddresses();
  const ratingAddress = getUserRatingAddress(addressData);

  const configPets = useSelector(state =>
    getPetPolicyConfig(state, ratingAddress.state_province)
  );

  const { hasRenewalModal } = useFlags();

  const { data: userData } = useUssr();
  const transactionFee = getUserTransactionFee(userData);
  const isAnnuallyCharged = getUserIsChargedAnnually(userData);

  const [isRenewal, setRenewal] = useState(false);

  useEffect(() => {
    const petsAlreadyOnCookies = Store.get(RENEWAL_MODAL_SHOWN_PETS);
    const petsWithRenewal = configPets.filter(
      pet => pet.renewalPolicy && !pet.isRenewalDay
    );

    if (petsWithRenewal.length && !petsAlreadyOnCookies?.length) {
      setRenewal(true);
    } else {
      //Removing from cookies, pets that probably already end date reached
      let nonExpiredPetsCookies = [];

      if (petsAlreadyOnCookies?.length) {
        nonExpiredPetsCookies = petsAlreadyOnCookies.filter(
          item => new Date(item.policyEndDate) > new Date()
        );
        Store.set(RENEWAL_MODAL_SHOWN_PETS, nonExpiredPetsCookies);
      }

      //Checking if pet with renewal is already on cookies
      if (petsWithRenewal?.length && nonExpiredPetsCookies?.length) {
        const withRenew = petsWithRenewal.map(pet => {
          return { id: pet.pet.id, policyEndDate: pet.policyEndDate };
        });

        // Showing renewal modal in case some pet isn't on cookie object
        setRenewal(
          !withRenew.every(elem =>
            nonExpiredPetsCookies.find(pet => pet.id === elem.id)
          )
        );
      }
    }
  }, [configPets]);

  function handleRenewalModalClose(closeType, page, modalType) {
    setRenewal(false);

    const petIds = configPets
      .filter(pet => pet.renewalPolicy && !pet.isRenewalDay)
      .map(pet => {
        return {
          id: pet.pet.id,
          policyEndDate: pet.policyEndDate,
        };
      });

    Store.set(RENEWAL_MODAL_SHOWN_PETS, petIds);
    analyticsTrack(renewalsModalClose(closeType, page, modalType));
  }

  function handleOnDownloadPlan(configPet) {
    const { renewalPlan } = configPet;

    if (hasCustomPlanDoc(renewalPlan.plan)) {
      downloadPlanCa();
    } else {
      downloadPlanCa(configPet.pet.species, renewalPlan.plan.version_number);
    }
  }

  return (
    <div className={styles.root}>
      <Header>My Profile</Header>

      <MyPetInformation />

      <Divider />

      <MyInformation />

      <Divider />

      <MyPassword />

      <Divider />

      <Paperless />

      {isRenewal && hasRenewalModal ? (
        <RenewalModal
          isAnnuallyCharged={isAnnuallyCharged}
          configPets={configPets}
          onClose={handleRenewalModalClose}
          onDownloadPolicy={downloadPolicyCa}
          onDownloadPlan={handleOnDownloadPlan}
          transactionFee={transactionFee}
        />
      ) : null}
    </div>
  );
}

export default Profile;
