import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Paths } from '../../../app-shell';
import { AccountBillingWrapper } from '../../../billing';
import getShouldDisplayLapsedBanner from '../../../billing/selectors/get-should-display-lapsed-banner';
import PaymentLapsedBanner from '../../../billing/view/payment-lapsed-banner';
import { AccountClaimsWrapper } from '../../../claims';
import ClinicsPage from '../clinics-page';
import MaintenanceModal from '../maintenance-modal';
import PetPolicy from '../pet-policy-wrapper';
import Profile from '../profile';

import styles from './member-center-legacy.css';

function MemberCenterLegacy() {
  const { allowMultiVetsPerPet, outageFlag } = useFlags();
  const { type, start, end } = outageFlag || {};
  const shouldDisplayLapsedBanner = useSelector(getShouldDisplayLapsedBanner);

  return type === 'scheduled-page' ? (
    <Redirect
      to={{
        pathname: Paths.MaintenanceFrame,
      }}
    />
  ) : (
    <div className={styles.root}>
      {type === 'scheduled-modal' ? (
        <MaintenanceModal timeStart={start} timeEnd={end} />
      ) : null}

      {shouldDisplayLapsedBanner ? <PaymentLapsedBanner /> : null}

      <div>
        <Switch>
          <Route path={Paths.Profile} component={Profile} />
          <Route path={Paths.Claims} component={AccountClaimsWrapper} />
          <Route path={Paths.Billing} component={AccountBillingWrapper} />
          <Route path={Paths.PetPolicy} component={PetPolicy} />
          <Route
            path={Paths.Clinics}
            component={() =>
              allowMultiVetsPerPet ? (
                <ClinicsPage />
              ) : (
                <Redirect to={Paths.Profile} />
              )
            }
          />

          <Redirect to={Paths.Profile} />
        </Switch>
      </div>
    </div>
  );
}

export default MemberCenterLegacy;
