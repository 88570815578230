import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, Route, Switch, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  DOG,
  FORMATTED_PARENTHESES_PHONE_NUMBER,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body2, LegalBody, Spinner, Tabs, Typography } from '@pumpkincare/shared/ui';
import {
  getPolicyStatusCopy,
  getUserPets,
  POLICY_STATUS,
  useUssr,
} from '@pumpkincare/user';
import { useUserPetPhoto } from '@pumpkincare/user';

import Paths from '../../../app-shell/paths';
import MemberCenterBanners from '../member-center-banners';
import PetClaims from './pet-claims';
import PetCoverage from './pet-coverage';
import PetRecords from './pet-records';

import styles from './pet-wrapper.css';

import catPlaceholder from './cat_placeholder.png';
import dogPlaceholder from './dog_placeholder.png';
import uploadIcon from './upload_Icon_camera.svg';

const PILLS = {
  Active: styles.active,
  'Renewal Declined': styles.renewalDeclined,
  Cancelled: styles.cancelled,
  'Billing Lapsed': styles.billingLapsed,
  Pending: styles.pending,
};

function getTabs(petId) {
  const coverage = Paths.PetCoverage.replace(/:petId/, petId);
  const claims = Paths.PetClaims.replace(/:petId/, petId);
  const records = Paths.PetRecords.replace(/:petId/, petId);

  return [
    {
      label: 'Plan',
      to: coverage,
      value: coverage,
    },
    {
      label: 'Claims',
      to: claims,
      value: claims,
    },
    {
      label: 'Records',
      to: records,
      value: records,
    },
  ];
}

function PetWrapper() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { punks1331PetPhotoFeature } = useFlags();
  const pathPetId = matchPath(location.pathname, Paths.PetsWrapper)?.params.petId;

  const { data: userData, isFetching: isUserFetching } = useUssr();
  const { data: userPetPhotoData } = useUserPetPhoto(pathPetId);
  const pets = getUserPets(userData);
  const pet = pets.find(pet => pet.id === pathPetId);
  const petName = pet?.name;
  const petImgPlaceholder = pet?.species === DOG ? dogPlaceholder : catPlaceholder;

  useEffect(() => {
    if (!isUserFetching && !petName) {
      dispatch(push(Paths.Landing));
    }
  }, [dispatch, isUserFetching, petName]);

  const policyStatus = pet && getPolicyStatusCopy(pet.policies);

  if (isUserFetching || !policyStatus) {
    return (
      <div className={styles.root}>
        <Spinner classes={{ root: styles.padding }} />
      </div>
    );
  }

  const isPending = policyStatus.toLowerCase() === POLICY_STATUS.PENDING;

  return (
    <>
      <MemberCenterBanners />

      <div className={styles.root}>
        <div className={styles.padding}>
          {punks1331PetPhotoFeature ? (
            <div className={styles.petIconContainer}>
              <div className={styles.petIconWrapper}>
                <img src={userPetPhotoData?.location || petImgPlaceholder} alt='' />
              </div>
              <img src={uploadIcon} alt='' />
            </div>
          ) : null}

          <div className={styles.title}>
            <h5>{petName}</h5>
            <h2>{petName}</h2>
            <LegalBody className={PILLS[policyStatus]}>{policyStatus}</LegalBody>
          </div>

          {!isPending ? (
            <>
              <Tabs
                tabsData={getTabs(pathPetId)}
                activeValue={location.pathname}
                classes={{
                  container: styles.tabContainer,
                  tabCopy: classNames(
                    Typography.body2,
                    Typography.bold,
                    styles.tabCopy
                  ),
                  tab: styles.tab,
                  activeCopy: styles.activeCopy,
                }}
              />
              <div className={styles.border} />
            </>
          ) : null}
        </div>

        {isPending ? (
          <Switch>
            <Route path={Paths.PetCoverage}>
              <div className={styles.warning}>
                <Body2>We have found an issue with your account.</Body2>
                <Body2>
                  Please call{' '}
                  <a href={PHONE_NUMBER_LINK}>
                    {FORMATTED_PARENTHESES_PHONE_NUMBER}
                  </a>{' '}
                  to talk to our team to help.
                </Body2>
              </div>
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path={Paths.PetCoverage}>
              <PetCoverage pet={pet} />
            </Route>

            <Route path={Paths.PetClaims}>
              <PetClaims petId={pet.id} />
            </Route>

            <Route path={Paths.PetRecords}>
              <PetRecords petId={pet.id} />
            </Route>
          </Switch>
        )}
      </div>
    </>
  );
}

export default PetWrapper;
