import {
  FORMATTED_PARENTHESES_PHONE_NUMBER,
  PHONE_NUMBER_LINK,
  useBooleanInput,
} from '@pumpkincare/shared';
import { Body2, ChevronRight, Plant } from '@pumpkincare/shared/ui';
import {
  getUserIsPaperless,
  POLICY_STATUS,
  useUserProfile,
  useUssr,
} from '@pumpkincare/user';

import PaperlessModal from '../paperless-modal';

import styles from './member-center-banners.css';

function PaperlessBanner({ openModal }) {
  return (
    <button onClick={openModal} className={styles.paperless}>
      <div style={{ width: '24px' }}>
        <Plant />
      </div>
      <Body2>Ditch the paper – go Paperless</Body2>
      <ChevronRight width={8} height={14} fill='var(--scoutNavy)' />
    </button>
  );
}

function PaymentsDueBanner() {
  return (
    <div className={styles.paymentsDue}>
      <Body2>
        Your payments are past due, please update your payment method or call{' '}
        <a href={PHONE_NUMBER_LINK}>
          <b>{FORMATTED_PARENTHESES_PHONE_NUMBER}</b>{' '}
          <ChevronRight width={8} height={14} fill='var(--scoutNavy)' />
        </a>
      </Body2>
    </div>
  );
}

function MemberCenterBanners() {
  const { data: paperlessData, isFetching: isPaperlessFetching } =
    useUserProfile(getUserIsPaperless);
  const { data: userData, isFetching: isUserFetching } = useUssr();

  const [isPaperlessModalOpen, togglePaperlessModalOpen] = useBooleanInput(false);

  const isFetching = [isPaperlessFetching, isUserFetching].some(status => !!status);
  const hasLapsedPet =
    !isFetching &&
    userData.pets.some(pet =>
      pet.policies.some(policy => policy.status === POLICY_STATUS.LAPSED)
    );
  const isPaperlessBannerVisible =
    !isFetching && !hasLapsedPet && paperlessData === false;

  return (
    <>
      {hasLapsedPet ? <PaymentsDueBanner /> : null}

      {isPaperlessBannerVisible ? (
        <PaperlessBanner openModal={togglePaperlessModalOpen} />
      ) : null}

      {isPaperlessModalOpen ? (
        <PaperlessModal handleCloseModal={togglePaperlessModalOpen} />
      ) : null}
    </>
  );
}

export default MemberCenterBanners;
