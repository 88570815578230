import * as Sentry from '@sentry/browser';
import { loadStripe } from '@stripe/stripe-js/pure';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { withLDConsumer, withLDProvider } from 'launchdarkly-react-client-sdk';
import { compose } from 'redux';

import {
  loadGoogleAnalytics,
  loadOneTrust,
  loadSegment,
  runQueuedExperiments,
  runQueuedTracks,
} from '@pumpkincare/analytics';
import { ENVIRONMENT, initLogRocket, STRIPE_API_KEY } from '@pumpkincare/config';
import { captureException } from '@pumpkincare/shared';

import getPageTitle from './utils/get-page-title';

import packageJson from '../../package.json';

export function configureApp() {
  if (ENVIRONMENT === 'production') {
    Sentry.init({
      environment: ENVIRONMENT,
      release: packageJson.version,
      dsn: 'https://a78e04b173e6488c8e65670944bd20a9@sentry.io/1496371',
    });

    initLogRocket('jnr91r/pumpkin-member-center');
  }

  initTimeOutScripts();
}

// any scripts that don't need to run on initial load should be here
function initTimeOutScripts() {
  const onSegmentReady = () => {
    runQueuedTracks();
    runQueuedExperiments({ analytics: true });
  };

  setTimeout(() => {
    loadSegment(onSegmentReady, getPageTitle(document.location.pathname));
    loadGoogleAnalytics();
    loadOneTrust();
  }, 200);
}

export function initStripe() {
  return loadStripe(STRIPE_API_KEY).catch(captureException);
}

export function initLaunchDarkly(clientSideID, user) {
  return {
    withLaunchDarkly: compose(
      withLDProvider({ clientSideID, user }),
      withLDConsumer()
    ),
    ldClient: LDClient.initialize(clientSideID, user),
  };
}
