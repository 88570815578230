import classNames from 'classnames';

import { useBooleanInput, usePaperlessAgreement } from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  DocumentButton,
  LoaderButton,
  Modal,
  Spinner,
  Typography,
} from '@pumpkincare/shared/ui';
import {
  getUserIsPaperless,
  useMutateUserPaperless,
  useUserProfile,
} from '@pumpkincare/user';

import PaperlessModal from '../../../paperless-modal';

// eslint-disable-next-line css-modules/no-unused-class
import modalStyles from '../../account-wrapper-modal-shared.css';
import sharedStyles from '../../account-wrapper-shared.css';
import styles from './documents-settings.css';

function DocumentsSettings() {
  const { data: profileData, isFetching: isProfileFetching } = useUserProfile();
  const {
    data: { paperless_terms_url, terms_version },
  } = usePaperlessAgreement();
  const { mutateAsync: mutateUserPaperless, isLoading: isMutatingUserPaperless } =
    useMutateUserPaperless();

  const [isMailDocumentsModalOpen, toggleMailDocumentsModal] =
    useBooleanInput(false);
  const [isPaperlessModalOpen, togglePaperlessModal] = useBooleanInput(false);

  const isPaperless = getUserIsPaperless(profileData);

  function handleTogglePaperlessModals() {
    if (!isPaperless) {
      togglePaperlessModal();
    } else {
      toggleMailDocumentsModal();
    }
  }

  function handleRevertToMailDocuments() {
    mutateUserPaperless({
      ...profileData,
      is_paperless: false,
      terms_version,
    }).then(() => toggleMailDocumentsModal());
  }

  return (
    <>
      <div className={sharedStyles.section}>
        <h5>Documents</h5>

        <div className={sharedStyles.cell}>
          <div className={sharedStyles.flex}>
            {isProfileFetching ? (
              <Spinner data-testid='profile-spinner' />
            ) : (
              <>
                <div className={sharedStyles.topLine}>
                  <Body2 isBold>Delivery Preference</Body2>

                  <button
                    onClick={handleTogglePaperlessModals}
                    className={Typography.action}
                  >
                    {isPaperless ? 'Mail Documents' : 'Set up Paperless'}
                  </button>
                </div>

                <Body2>{isPaperless ? 'Paperless' : 'Mail documents'}</Body2>

                {isPaperless && paperless_terms_url ? (
                  <DocumentButton
                    title='Go Paperless with Pumpkin Consent Agreement'
                    href={paperless_terms_url}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>

      {isMailDocumentsModalOpen ? (
        <Modal
          onClose={toggleMailDocumentsModal}
          classes={{
            container: modalStyles.modalContainer,
            content: modalStyles.modal,
          }}
        >
          <h3>Want documents mailed?</h3>
          <h5>Want documents mailed?</h5>

          <div className={modalStyles.modalBody}>
            <Body2>
              We encourage you to stayed opted-in to paperless, as it’s the most
              secure way to access your Pumpkin docs at any time.
            </Body2>
          </div>

          <LoaderButton
            onClick={handleRevertToMailDocuments}
            color='primary'
            isLoading={isMutatingUserPaperless}
            classes={{ root: styles.paperlessModalButton }}
          >
            Revert to mail documents
          </LoaderButton>

          <button
            onClick={toggleMailDocumentsModal}
            className={classNames(
              styles.paperlessModalButton,
              modalStyles.closeModalButton,
              ButtonStyles.baseButton
            )}
          >
            Keep Paperless
          </button>
        </Modal>
      ) : null}

      {isPaperlessModalOpen ? (
        <PaperlessModal handleCloseModal={togglePaperlessModal} />
      ) : null}
    </>
  );
}

export default DocumentsSettings;
