// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".clinics-delete-modal_content_b83f148f {\n    padding-top: 110px;\n}\n\nh4.clinics-delete-modal_header_f04e8691 {\n    color: var(--bluesCluesBlue);\n}\n\n.clinics-delete-modal_list_9393e318 {\n    padding-left: 16px;\n}\n\n.clinics-delete-modal_list_9393e318 li {\n    color: var(--gromitGray);\n}\n\n.clinics-delete-modal_buttonsContainer_01d059db {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n}\n\n.clinics-delete-modal_buttonsContainer_01d059db button {\n    width: 100%;\n    margin-bottom: 0;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + " {\n    .clinics-delete-modal_content_b83f148f {\n        padding: 32px 16%;\n    }\n\n    .clinics-delete-modal_buttonsContainer_01d059db {\n        flex-direction: row;\n    }\n\n    .clinics-delete-modal_buttonsContainer_01d059db button {\n        width: 48%;\n    }\n}", "",{"version":3,"sources":["webpack://src/user/view/clinics-delete-modal/clinics-delete-modal.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":["@value b-desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.content {\n    padding-top: 110px;\n}\n\nh4.header {\n    color: var(--bluesCluesBlue);\n}\n\n.list {\n    padding-left: 16px;\n}\n\n.list li {\n    color: var(--gromitGray);\n}\n\n.buttonsContainer {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n}\n\n.buttonsContainer button {\n    width: 100%;\n    margin-bottom: 0;\n}\n\n@media b-desktop {\n    .content {\n        padding: 32px 16%;\n    }\n\n    .buttonsContainer {\n        flex-direction: row;\n    }\n\n    .buttonsContainer button {\n        width: 48%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + "",
	"content": "clinics-delete-modal_content_b83f148f",
	"header": "clinics-delete-modal_header_f04e8691",
	"list": "clinics-delete-modal_list_9393e318",
	"buttonsContainer": "clinics-delete-modal_buttonsContainer_01d059db"
};
export default ___CSS_LOADER_EXPORT___;
