const Paths = {
  Error: '/error',
  Maintenance: '/maintenance',
  MaintenanceFrame: '/maintenance-frame',

  // auth
  ForgotPassword: '/forgot-password',
  Login: '/login',
  Registration: '/register',

  // legacy
  Account: '/account/:component',
  AccountVetForm: '/account/pet/:petId/vet-form',
  Billing: '/account/billing',
  Claims: '/account/claims',
  PetPolicy: '/account/pet-policy',
  Profile: '/account/profile',
  Clinics: '/account/clinics',

  // redesigned MC
  Landing: '/profile',
  PetsWrapper: '/pets/:petId',
  PetCoverage: '/pets/:petId/plan',
  PetClaims: '/pets/:petId/claims',
  PetRecords: '/pets/:petId/records',
  AccountWrapper: '/user',
  Overview: '/user/overview',
  Reimbursement: '/user/reimbursement',
  NewBilling: '/user/billing',
  Support: '/support',

  ClaimsSubmission: '/claim-submission',
  ClaimsSubmissionSuccess: '/claim-submission-success',
  DirectDeposit: '/direct-deposit',
  DirectDepositSuccess: '/direct-deposit-success',
};

export default Paths;
