import { FETCHING } from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  LegalBody,
  LoaderButton,
  Modal,
} from '@pumpkincare/shared/ui';

import styles from './clinics-delete-modal.css';

export default function ClinicsDeleteModal({ onClose, onDelete, status }) {
  const isLoading = status === FETCHING;

  return (
    <Modal onClose={onClose} classes={{ content: styles.content }}>
      <h4 className={styles.header}>
        Are you sure you want to remove this vet clinic from your account?
      </h4>

      <Body2 style={{ fontWeight: 600 }}>
        Removing will cause this clinic to lose the following permissions:
      </Body2>

      <ol className={styles.list}>
        <li>
          <LegalBody style={{ marginTop: '8px' }}>
            The ability for Pumpkin to contact this vet in order to gather claim
            related information
          </LegalBody>
        </li>
        <li>
          <LegalBody>
            Share your name & the name of your pet(s) with this vet in order to make
            them aware of your Pumpkin affiliation
          </LegalBody>
        </li>
        <li>
          <LegalBody>
            Consent for this vet to file a claim on your pet’s behalf
          </LegalBody>
        </li>
        <li>
          <LegalBody>Share your Pumpkin claims and status with this vet.</LegalBody>
        </li>
      </ol>

      <div className={styles.buttonsContainer}>
        <LoaderButton color='primary' onClick={onDelete} isLoading={isLoading}>
          Yes
        </LoaderButton>

        <button className={ButtonStyles.secondary} onClick={onClose}>
          No
        </button>
      </div>
    </Modal>
  );
}
