// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mailing-address-update-modal_addressModalButton_466127eb {\n    margin-top: 24px;\n    margin-bottom: 32px;\n    width: 327px;\n    border-radius: 8px;\n}\n\n.mailing-address-update-modal_modalImg_9cdf1587 {\n    align-self: center;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/mailing-address-update-modal/mailing-address-update-modal.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".addressModalButton {\n    margin-top: 24px;\n    margin-bottom: 32px;\n    width: 327px;\n    border-radius: 8px;\n}\n\n.modalImg {\n    align-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addressModalButton": "mailing-address-update-modal_addressModalButton_466127eb",
	"modalImg": "mailing-address-update-modal_modalImg_9cdf1587"
};
export default ___CSS_LOADER_EXPORT___;
