import { getPlanForDate } from '@pumpkincare/plans';
import {
  calculateAndFormatISODate,
  dateIsAfter,
  formatISODate,
  getIsPuppy,
  isPuppyPrevent,
  validateDateMMDDYYY,
} from '@pumpkincare/shared';
import { getLatestRelevantPolicy } from '@pumpkincare/user';

import validateClaimsDate from './validate-claim-dates';

function validateInvoices({ fileList, errors }) {
  if (fileList.length === 0) {
    errors.itemizedInvoice = {
      title: 'Itemized invoice',
      message: 'This field is required.',
    };
  }

  if (fileList.some(item => item.error)) {
    errors.itemizedInvoice = { title: 'Itemized invoice' };
  }
}

function validateMedicalRecords({ medicalRecordsFileList, errors }) {
  if (medicalRecordsFileList.some(item => item.error)) {
    errors.medicalRecords = { title: 'Medical records' };
  }
}

function validateClinicSelection({ selectedVet, errors }, { shouldValidateClinic }) {
  if (shouldValidateClinic && !selectedVet) {
    errors.clinic = { title: 'Which clinic' };
  }
}

function validateMultipleClinics(
  { isInsurance, isMultiVet, errors },
  { shouldValidateMultiVetAndOngoing }
) {
  if (
    shouldValidateMultiVetAndOngoing &&
    isInsurance &&
    typeof isMultiVet !== 'boolean'
  ) {
    errors.multiClinics = { title: 'Were multiple clinics visited' };
  }
}

function validateDateList({
  dateList,
  isPreventiveEssentials,
  isInsurance,
  coverageStartDate,
  selectedPet,
  errors,
}) {
  const dateListValidated = dateList.map(dateItem => {
    // deep nested objects and object permanence in JS :)
    const item = { ...dateItem };

    /*
     if isPEP:
     - puppy claims are valid as long as date is provided
     - otherwise claims can be 14 days before effective date
     - can ignore date validation if also isIns (claims team will handle)
     */
    if (isPreventiveEssentials) {
      if (validateDateMMDDYYY(item.dateOfVisit) === false) {
        item.dateOfVisitErrorMessage =
          'Please enter a valid date format (MM/DD/YYYY)';
      }

      // quick check to make sure this is a puppy plan (checking length since if there's more than one plan, it cannot be puppy)
      if (
        selectedPet.plans.length === 1 &&
        isPuppyPrevent(selectedPet.plans[0].plan)
      ) {
        return item;
      }

      const plan = getPlanForDate(selectedPet?.plans, item.dateOfVisit);

      if (!plan) {
        item.dateOfVisitErrorMessage =
          'Date of visit is outside your Insurance coverage date';
      }

      return item;
    }

    /*
     if isIns only:
     - validate against insuranceStartDate
     -- iSD determined in claims-submission.js
     -- iSD accounts for waiting period which can vary by state / model law
     if neither isIns or isPep:
     - still validate that dateOfVisit is valid
     */
    const validDateResult = validateClaimsDate({
      dateStr: item.dateOfVisit,
      dateLabel: 'Date of visit',
      insuranceStartDateStr: coverageStartDate,
      shouldValidateCoverage: isInsurance,
    });

    if (validDateResult !== true) {
      item.dateOfVisitErrorMessage = validDateResult;
    }

    return item;
  });

  if (dateListValidated.some(item => !!item.dateOfVisitErrorMessage)) {
    errors.dateList = {
      title: 'Date of visit',
      messages: dateListValidated.map(item => item.dateOfVisitErrorMessage),
    };
  }

  return dateListValidated;
}

function validateTotalAmountList({ totalAmountList, errors }) {
  const totalAmountListValidated = totalAmountList.map(item => {
    item.amountErrorMessage = '';
    item.amountError = false;

    if (!item.totalAmountClaimed) {
      item.amountErrorMessage = 'This field is required.';
    }

    if (isNaN(item.totalAmountClaimed)) {
      item.amountErrorMessage = 'Invalid value.';
    }

    return item;
  });

  if (totalAmountListValidated.some(item => !!item.amountErrorMessage)) {
    errors.amount = {
      title: 'Total amount claimed',
      messages: totalAmountListValidated.map(item => item.amountErrorMessage),
    };
  }
}

function validateClaimType({ isInsurance, isPreventiveEssentials, errors }) {
  if (!isInsurance && !isPreventiveEssentials) {
    errors.claimType = {
      title: 'Type of claim',
      message: 'Please choose a claim type.',
    };
  }
}

function validateDateOfIncident({
  selectedPet,
  dateListValidated,
  isInsurance,
  isPreventiveEssentials,
  relevantPolicy,
  coverageStartDate,
  injuryDate,
  errors,
}) {
  if (isInsurance) {
    const newInsuranceStartDate = isPreventiveEssentials
      ? calculateAndFormatISODate(relevantPolicy.policy_effective_date, '-14d')
      : coverageStartDate;

    const validDateResult =
      isPreventiveEssentials && getIsPuppy(selectedPet)
        ? true
        : validateClaimsDate({
            dateStr: injuryDate,
            dateLabel: 'Date of accident/illness',
            insuranceStartDateStr: newInsuranceStartDate,
          });

    if (validDateResult !== true) {
      errors.dateOfIncident = {
        title: 'Date of accident/illness',
        message: validDateResult,
      };
      return;
    }

    const firstValidVisitDate = dateListValidated
      .filter(
        ({ dateOfVisit, dateOfVisitErrorMessage }) =>
          dateOfVisit && !dateOfVisitErrorMessage
      )
      .sort((a, b) => new Date(a.dateOfVisit) - new Date(b.dateOfVisit))[0];

    if (
      !firstValidVisitDate ||
      dateIsAfter(injuryDate, firstValidVisitDate.dateOfVisit)
    ) {
      errors.dateOfIncident = {
        title: 'Date of accident/illness',
        message: 'Accident/illness date should be before date of visit',
      };
    }
  }
}

function validateCondition(
  { isInsurance, isOngoing, errors },
  { shouldValidateMultiVetAndOngoing }
) {
  if (
    shouldValidateMultiVetAndOngoing &&
    isInsurance &&
    typeof isOngoing !== 'boolean'
  ) {
    errors.newCondition = { title: 'Is this a new condition' };
  }
}

function validateVetReason({ vetVisitReason, errors }) {
  if (vetVisitReason === '') {
    errors.vetVisit = {
      title: 'Reason for vet visit',
      message: 'This field is required.',
    };
  }
}

/* @function validateClaimSubmissionFields()
 *
 * @param {array} fileList - array of required files (e.g. invoice) via FileUpload component (input by user)
 * @param {array} medicalRecordsFileList - array of optional medical records via FileUpload component (input by user)
 * @param {boolean} isPreventEssentials - claim type (input by user)
 * @param {boolean} isInsuranceIllness - claim type (input by user)
 * @param {boolean} isInsuranceAccident - claim type (input by user)
 * @param {object} selectedPet (input by user)
 * @param {array} dateList - array of dates associated with the claim visit (input by user)
 * @param {array} totalAmountList - array of claim amounts (matches dateList, input by user)
 * @param {string} injuryDate - loss date only provided if accident or illness (input by user)
 * @param {string} vetVisitReason - additional info for the claim from pet parent (input by user)
 * @param {string} selectedVet - which clinic is associated with the claim (input by user, but feature flagged)
 * @param {boolean} isMultiVet - are multiple clinics associated with the claim (input by user, but feature flagged)
 * @param {boolean} isOngoing - is a visit for an ongoing condition (input by user, but feature flagged)
 *
 * See {@link https://www.notion.so/pumpkincare/dcac0aed478241f1a08ebd24c065dec1|here for more info on validation}
 *
 * @returns {object} - formatted errors objects
 * */
export default function validateClaimSubmissionFields(
  {
    fileList,
    medicalRecordsFileList = [],
    isPreventiveEssentials,
    isInsuranceIllness,
    isInsuranceAccident,
    selectedPet = {},
    dateList,
    totalAmountList,
    injuryDate,
    vetVisitReason,
    selectedVet,
    isMultiVet,
    isOngoing,
  },
  { shouldValidateMultiVetAndOngoing, shouldValidateClinic } = {}
) {
  const errors = {};
  const isInsurance = isInsuranceAccident || isInsuranceIllness;
  // latestRelevantPolicy may be canceled or active
  const relevantPolicy = getLatestRelevantPolicy(selectedPet.policies);

  const isModelLaw =
    relevantPolicy.policy_accident_coverage_start_date !==
    relevantPolicy.policy_illness_coverage_start_date;
  const coverageStartDate =
    relevantPolicy.id &&
    formatISODate(
      isModelLaw && isInsuranceAccident
        ? relevantPolicy.policy_accident_coverage_start_date
        : relevantPolicy.policy_illness_coverage_start_date
    );

  validateInvoices({ fileList, errors });
  validateMedicalRecords({ medicalRecordsFileList, errors });
  validateClinicSelection({ selectedVet, errors }, { shouldValidateClinic });
  validateMultipleClinics(
    { isInsurance, isMultiVet, errors },
    { shouldValidateMultiVetAndOngoing }
  );
  const dateListValidated = validateDateList({
    dateList,
    isPreventiveEssentials,
    isInsurance,
    coverageStartDate,
    selectedPet,
    errors,
  });
  validateTotalAmountList({
    totalAmountList,
    errors,
  });
  validateClaimType({ isInsurance, isPreventiveEssentials, errors });
  validateDateOfIncident({
    selectedPet,
    dateListValidated,
    isInsurance,
    isPreventiveEssentials,
    relevantPolicy,
    coverageStartDate,
    injuryDate,
    errors,
  });
  validateCondition(
    { isInsurance, isOngoing, errors },
    { shouldValidateMultiVetAndOngoing }
  );
  validateVetReason({ vetVisitReason, errors });

  return {
    errors,
  };
}
