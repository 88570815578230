import { Fragment } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { WWW_URL } from '@pumpkincare/config';
import { OFFICE_LOCATION } from '@pumpkincare/shared';
import { ChevronLeft, LegalBody, Typography } from '@pumpkincare/shared/ui';

import styles from './footer-logged-in.css';

const NOTICES = [
  {
    state: 'CALIFORNIA',
    href: `${WWW_URL}/privacy-center/california-privacy-disclosure/`,
  },
  { state: 'MAINE', href: `${WWW_URL}/notice-to-maine-residents/` },
];

const LINKS = [
  { title: 'Terms of Use', href: `${WWW_URL}/terms-of-use/` },
  { title: 'Privacy Center', href: `${WWW_URL}/privacy-center/` },
  { title: 'Do Not Sell/Share My Personal Information', id: 'ot-sdk-btn' },
  { title: 'Site Map', href: `${WWW_URL}/sitemap_index.xml/` },
  { title: 'Underwriting', href: `${WWW_URL}/underwriting-information/` },
  { title: 'Insurance Licenses', href: `${WWW_URL}/insurance-licenses/` },
];

function FooterLoggedIn() {
  const { fer3172ModelLaw } = useFlags();

  return (
    <div className={styles.root}>
      <LegalBody className={styles.text}>
        Pumpkin Pet Insurance policies do not cover pre-existing conditions. Waiting
        periods, annual deductible, co-insurance, benefit limits and exclusions may
        apply. For full terms, visit{' '}
        <a
          href={`${WWW_URL}/sample-plan/`}
          target='_blank'
          rel='noopener noreferrer'
        >
          pumpkin.care/insurancepolicy
        </a>
        . Products and rates may vary and are subject to change. Discounts may vary
        and are subject to change. Insurance products are underwritten by either
        Independence American Insurance Company (NAIC #26581. A Delaware insurance
        company headquarters located at 11333 N. Scottsdale Rd, Ste. 160, Scottsdale,
        AZ 85254), or United States Fire Insurance Company (NAIC #21113. Morristown,
        NJ). Please refer to your policy forms to determine the underwriter for your
        policy. Insurance is administered and produced by Pumpkin Insurance Services
        Inc.(“Pumpkin”)(NPN #19084749; Domiciled in New York with offices at{' '}
        {OFFICE_LOCATION}; CA License #6001617). Pumpkin is a licensed insurance
        agency, not an insurer, and receives compensation based on the premiums for
        the insurance policies it sells. For more details, visit{' '}
        <a
          href={`${WWW_URL}/underwriting-information/`}
          target='_blank'
          rel='noopener noreferrer'
        >
          pumpkin.care/underwriting-information
        </a>
        . Pumpkin Preventive Essentials is not an insurance policy. It is offered as
        an optional add-on non-insurance benefit. Pumpkin is responsible for the
        product and administration. For full terms, visit{' '}
        <a href={`${WWW_URL}/sample-plan`} target='_blank' rel='noopener noreferrer'>
          pumpkin.care/customeragreement
        </a>
        . Pumpkin Preventive Essentials is not available in all states.
      </LegalBody>
      {fer3172ModelLaw ? (
        <div className={styles.disclosures}>
          <a
            href={`${WWW_URL}/insurer-disclosure`}
            target='_blank'
            rel='noopener noreferrer'
            className={classNames(styles.disclosure, styles.text, Typography.bold)}
          >
            INSURER DISCLOSURE OF IMPORTANT POLICY PROVISIONS
            <ChevronLeft className={styles.chevron} />
          </a>
        </div>
      ) : (
        <div className={styles.disclosures}>
          {NOTICES.map(notice => (
            <a
              key={notice.state}
              href={notice.href}
              target='_blank'
              rel='noopener noreferrer'
              className={classNames(styles.disclosure, styles.text, Typography.bold)}
            >
              NOTICE TO {notice.state} RESIDENTS
              <ChevronLeft className={styles.chevron} />
            </a>
          ))}
        </div>
      )}

      <div className={styles.center}>
        <LegalBody>
          © {new Date().getFullYear()} Pumpkin Insurance Services Inc. All rights
          reserved
        </LegalBody>

        <div className={styles.links}>
          {LINKS.map((link, idx) => (
            <Fragment key={link.title}>
              {link.id ? (
                <button
                  id={link.id}
                  className={classNames(
                    'ot-sdk-show-settings',
                    styles.onetrustLink,
                    Typography.legalBody
                  )}
                >
                  {link.title}
                </button>
              ) : (
                <a
                  href={link.href}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={Typography.legalBody}
                >
                  {link.title}
                </a>
              )}

              {idx !== LINKS.length - 1 ? (
                <div className={styles.separator} />
              ) : null}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FooterLoggedIn;
