import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Heart, LegalBody, Paw } from '@pumpkincare/shared/ui';

import styles from './member-callout.css';

function MemberCallout({ type, items, children, className }) {
  const hasChildren = !!children?.filter(x => x).length;

  return (
    <div className={classNames(styles.root, className)}>
      <div
        className={classNames(styles.content, {
          [styles.shrink]: hasChildren,
        })}
      >
        <div className={styles.icon}>
          {type === 'pet' ? <Paw /> : <Heart uneven />}
        </div>

        <div className={styles.items}>
          {items.map(item => (
            <div key={item.title} className={styles.item}>
              <LegalBody>{item.title}</LegalBody>
              <LegalBody isBold dangerouslySetInnerHTML={{ __html: item.copy }} />
            </div>
          ))}
        </div>
      </div>

      {hasChildren ? <div className={styles.aside}>{children}</div> : null}
    </div>
  );
}

MemberCallout.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['pet', 'user']).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default MemberCallout;
