import { useCallback, useEffect, useState } from 'react';

import {
  GENERIC_TEMP_ERROR,
  useBanners,
  useBooleanInput,
} from '@pumpkincare/shared';
import { Body2 } from '@pumpkincare/shared/ui';
import {
  deleteUserAch,
  getReimbursementTargetAccountNumber,
  getReimbursementTargetNameOnAccount,
  usePayment,
} from '@pumpkincare/user';

import DeleteDirectDepositModal from '../../direct-deposit-setup/delete-direct-deposit-modal';
import DirectDepositSetupModal from '../../direct-deposit-setup-modal';

import styles from './reimbursement-page.css';

function ReimbursementPage() {
  const {
    data: paymentData,
    isPlaceholderData: isPaymentPlaceholderData,
    isFetching: isPaymentFetching,
  } = usePayment();
  const [accountNumber, setAccountNumber] = useState('');
  const nameOnAccount = getReimbursementTargetNameOnAccount(paymentData);

  const [isDeleteModalOpen, toggleIsDeleteModalOpen] = useBooleanInput(false);
  const [isSetupModalOpen, toggleIsSetupModalOpen] = useBooleanInput(false);

  const { addBanner, removeAllBanners } = useBanners();

  useEffect(() => {
    setAccountNumber(getReimbursementTargetAccountNumber(paymentData));
  }, [paymentData]);

  const handleDelete = useCallback(
    e => {
      e.preventDefault();

      removeAllBanners();

      deleteUserAch()
        .then(() => setAccountNumber(''))
        .catch(() => addBanner(GENERIC_TEMP_ERROR))
        .finally(toggleIsDeleteModalOpen);
    },

    [removeAllBanners, toggleIsDeleteModalOpen, addBanner]
  );

  return (
    <>
      <div className={styles.root}>
        <h5 className={styles.title}>How to get paid from claims</h5>
        <div className={styles.content}>
          <div className={styles.methodInfo}>
            <Body2 isBold>Reimbursement Method</Body2>
            {isPaymentFetching && isPaymentPlaceholderData ? null : accountNumber ? (
              <Body2>
                Bank Account •••• {accountNumber}&nbsp;&nbsp;|&nbsp;&nbsp;
                {nameOnAccount}
              </Body2>
            ) : (
              <Body2>Mail check</Body2>
            )}
          </div>
          {isPaymentFetching && isPaymentPlaceholderData ? null : (
            <div className={accountNumber ? styles.linksSmall : styles.links}>
              <a role='button' onClick={toggleIsSetupModalOpen}>
                {accountNumber ? 'Update Bank Details' : 'Add Direct Deposit'}
              </a>
              {accountNumber ? (
                <div>
                  <span className={styles.divider}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                  <a role='button' onClick={toggleIsDeleteModalOpen}>
                    Remove Direct Deposit
                  </a>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className={styles.border} />
      </div>

      {isDeleteModalOpen ? (
        <DeleteDirectDepositModal
          onSubmit={handleDelete}
          onCancel={toggleIsDeleteModalOpen}
          isLoading={isPaymentFetching && isPaymentPlaceholderData}
        />
      ) : null}

      {isSetupModalOpen ? (
        <DirectDepositSetupModal onCancel={toggleIsSetupModalOpen} />
      ) : null}
    </>
  );
}

export default ReimbursementPage;
