import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { PUMPKIN_ASSETS_URL } from '@pumpkincare/config';
import { Body1, Body2, ButtonStyles, Typography } from '@pumpkincare/shared/ui';

import { Paths } from '../../../../../app-shell';

import styles from './claims-how-to-file.css';

function ClaimsHowToFile({ petId }) {
  return (
    <div className={styles.root}>
      <div className={styles.fileContainer}>
        <h3 className={styles.header}>File a Claim</h3>
        <Body1>
          {`Simply enter your claim info, upload your itemized vet bill and submit!
            Let's get started.`}
        </Body1>

        <Link
          to={`${Paths.ClaimsSubmission}?pet_id=${petId}`}
          className={styles.claimSubmissionLink}
        >
          <button
            className={classNames(ButtonStyles.primary, styles.claimsButton)}
            data-testid='file-claim-button'
          >
            File a Claim
          </button>
        </Link>
      </div>

      <div className={styles.offlineContainer}>
        <Body2 className={classNames(styles.offlineHeader, Typography.h5)}>
          Want to file a claim offline? No problem.
        </Body2>

        <Body1 style={{ marginTop: '20px' }}>
          {'First '}
          <a
            href={PUMPKIN_ASSETS_URL + '/pdfs/pumpkin-claim-form.pdf'}
            target='_blank'
            rel='noreferrer'
            className={styles.offlineLink}
          >
            download a claim form
          </a>
          , fill it out and send both the form and your itemized vet bill:
        </Body1>

        <Body1 style={{ marginTop: '20px' }}>
          <a
            href={'mailto:claims@pumpkin.care?subject=New Claim Submission'}
            target='_blank'
            rel='noreferrer'
            className={styles.offlineLink}
          >
            claims@pumpkin.care
          </a>
          {' or mail to:'}
        </Body1>

        <Body2 style={{ marginTop: '20px' }}>Pumpkin Insurance Services Inc.</Body2>
        <Body1>
          PO Box 307
          <br />
          Broadway, NJ 08808-0307
        </Body1>
      </div>
    </div>
  );
}

ClaimsHowToFile.propTypes = {
  petId: PropTypes.string.isRequired,
};

export default ClaimsHowToFile;
