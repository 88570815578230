import { useEffect } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { analyticsTrack, INITIATE_EXPERIMENT } from '@pumpkincare/analytics';
import { QF_URL } from '@pumpkincare/config';
import { useBooleanInput } from '@pumpkincare/shared';
import {
  ButtonStyles,
  ChevronRight,
  LegalBody,
  Link,
  Spinner,
} from '@pumpkincare/shared/ui';
import { getPetsByStatus, getUserPets, PetCard, useUssr } from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import MemberCenterBanners from '../member-center-banners';

import styles from './member-pets.css';

import callout from './callout.png';

function calcColumns(num) {
  if (num <= 3) return num;
  if (num === 4) return 2;

  return 3;
}

function MemberPets() {
  const [isAllPets, toggleAllPets] = useBooleanInput(false);
  const { punks1331PetPhotoFeature } = useFlags();

  const { data: petsData, isFetching } = useUssr(getUserPets);

  const { activePets, inactivePets } = getPetsByStatus(petsData);
  const columns = calcColumns(activePets.length || inactivePets.length);

  useEffect(() => {
    analyticsTrack({
      category: 'Pet photo feature',
      event: INITIATE_EXPERIMENT,
      label: punks1331PetPhotoFeature ? 'b_test' : 'a_control',
    });
  }, [punks1331PetPhotoFeature]);

  return (
    <>
      <MemberCenterBanners />

      <div className={styles.root}>
        <div className={styles.title}>
          <h5>Pumpkin Pets</h5>
          <h2>Pumpkin Pets</h2>
        </div>

        {isFetching ? (
          <Spinner classes={{ root: styles.spinner }} />
        ) : (
          <>
            <div
              className={classNames(styles.pets, {
                [styles.col2]: columns === 2,
                [styles.col3]: columns === 3,
              })}
            >
              {activePets.map(pet => {
                return (
                  <PetCard
                    key={pet.id}
                    pet={pet}
                    toPet={`/pets/${pet.id}/plan`}
                    toClaim={`${Paths.ClaimsSubmission}?pet_id=${pet.id}`}
                  />
                );
              })}

              {isAllPets || !activePets.length
                ? inactivePets.map(pet => {
                    return (
                      <PetCard
                        key={pet.id}
                        pet={pet}
                        toPet={`/pets/${pet.id}/plan`}
                        toClaim={`${Paths.ClaimsSubmission}?pet_id=${pet.id}`}
                      />
                    );
                  })
                : null}
            </div>

            {inactivePets.length && activePets.length ? (
              <button
                className={classNames(styles.toggle, ButtonStyles.cta)}
                onClick={toggleAllPets}
              >
                {isAllPets ? 'Show Active Pets Only' : 'Show All Pets'}
                <ChevronRight
                  width={8}
                  height={14}
                  className={isAllPets ? styles.open : styles.closed}
                />
              </button>
            ) : null}
          </>
        )}

        <div className={styles.callout}>
          <img src={callout} alt='' />

          <LegalBody>
            Get a 10% multi-pet discount for each additional pet you enroll
          </LegalBody>

          <Link href={QF_URL}>
            <button className={ButtonStyles.secondary}>Add a pet</button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default MemberPets;
