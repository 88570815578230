import { Redirect, Route } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getIsLoggedIn } from '@pumpkincare/shared';
import {
  ErrorPage,
  MaintenanceFrame,
  MaintenancePage,
} from '@pumpkincare/shared/pages';

import { ClaimsSubmission, ClaimsSubmissionSuccess } from '../claims';
import {
  AccountWrapper,
  DirectDepositSetupPage,
  DirectDepositSuccess,
  ForgotPassword,
  LoginPage,
  MemberCenterLegacy,
  MemberPets,
  PetWrapper,
  Registration,
  Support,
} from '../user';
import Page from './view/page';
import PrivatePage from './view/private-page';
import Paths from './paths';

function Routes() {
  const { outageFlag, dawgs2016MemberCenterRedesign } = useFlags();
  const isLoggedIn = getIsLoggedIn();

  return (
    <>
      {outageFlag && outageFlag?.type === 'outage' ? (
        <Redirect from={Paths.Login} to={Paths.Maintenance} />
      ) : null}

      <Route path={'/'} exact>
        {isLoggedIn ? (
          <Redirect
            to={dawgs2016MemberCenterRedesign ? Paths.Landing : Paths.Account}
          />
        ) : (
          <Redirect to={Paths.Login} />
        )}
      </Route>

      <Route path={Paths.Error}>
        <Page>
          <ErrorPage />
        </Page>
      </Route>

      <Route path={Paths.Maintenance}>
        <Page>
          <MaintenancePage />
        </Page>
      </Route>

      <Route path={Paths.MaintenanceFrame}>
        <Page isNavBarVisible={false} isFooterVisible={false}>
          <MaintenanceFrame />
        </Page>
      </Route>

      <Route path={Paths.Login}>
        <Page>
          <LoginPage />
        </Page>
      </Route>

      <Route path={Paths.ForgotPassword}>
        <Page>
          <ForgotPassword />
        </Page>
      </Route>

      <Route path={Paths.Registration}>
        <Page>
          <Registration />
        </Page>
      </Route>

      <Route path={Paths.DirectDeposit}>
        <PrivatePage isNavBarVisible={false}>
          <DirectDepositSetupPage />
        </PrivatePage>
      </Route>

      <Route path={Paths.DirectDepositSuccess}>
        <PrivatePage isNavBarVisible={false}>
          <DirectDepositSuccess />
        </PrivatePage>
      </Route>

      <Route path={Paths.ClaimsSubmission}>
        <PrivatePage isNavBarVisible={false}>
          <ClaimsSubmission />
        </PrivatePage>
      </Route>

      <Route path={Paths.ClaimsSubmissionSuccess}>
        <PrivatePage isNavBarVisible={false}>
          <ClaimsSubmissionSuccess />
        </PrivatePage>
      </Route>

      {dawgs2016MemberCenterRedesign ? (
        <>
          <Route path={Paths.Landing}>
            <PrivatePage>
              <MemberPets />
            </PrivatePage>
          </Route>

          <Route path={Paths.PetsWrapper}>
            <PrivatePage>
              <PetWrapper />
            </PrivatePage>
          </Route>

          <Route path={Paths.AccountWrapper}>
            <PrivatePage>
              <AccountWrapper />
            </PrivatePage>
          </Route>

          <Route path={Paths.Support}>
            <PrivatePage>
              <Support />
            </PrivatePage>
          </Route>

          {typeof dawgs2016MemberCenterRedesign === 'boolean' ? (
            <Route path={Paths.Account}>
              <Redirect to={Paths.Landing} />
            </Route>
          ) : null}
        </>
      ) : (
        <>
          <Route path={Paths.Account}>
            <PrivatePage>
              <MemberCenterLegacy />
            </PrivatePage>
          </Route>

          {typeof dawgs2016MemberCenterRedesign === 'boolean' ? (
            <Route path={Paths.Landing}>
              <Redirect to={Paths.Account} />
            </Route>
          ) : null}
        </>
      )}
    </>
  );
}

export default Routes;
