// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paperless-modal_header_b6b91af0 {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n}\n\n.paperless-modal_callout_427b2cf4 {\n    width: 100%;\n    background: var(--cujoCream);\n    padding: 24px;\n    box-sizing: border-box;\n    margin: 24px 0 8px;\n}\n\n.paperless-modal_submitButton_3ccc7749 {\n    width: 100%;\n    border-radius: 8px;\n}", "",{"version":3,"sources":["webpack://src/user/view/paperless-modal/paperless-modal.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":["@value tablet, desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.header {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n}\n\n.callout {\n    width: 100%;\n    background: var(--cujoCream);\n    padding: 24px;\n    box-sizing: border-box;\n    margin: 24px 0 8px;\n}\n\n.submitButton {\n    width: 100%;\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + "",
	"header": "paperless-modal_header_b6b91af0",
	"callout": "paperless-modal_callout_427b2cf4",
	"submitButton": "paperless-modal_submitButton_3ccc7749"
};
export default ___CSS_LOADER_EXPORT___;
