import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  COUNTRY_OPTIONS,
  postBillingAddressValidate,
  US_STATES,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  ButtonStyles,
  LegalBody,
  LoaderButton,
  Modal,
  Select,
  TextField,
} from '@pumpkincare/shared/ui';
import { useMutateUserBillingAddress } from '@pumpkincare/user';

// eslint-disable-next-line css-modules/no-unused-class
import modalStyles from '../../account-wrapper-modal-shared.css';
import styles from './billing-address-update-modal.css';

const MAPPED_STATES = Object.keys(US_STATES).map(item => ({
  value: item,
  label: item,
  name: 'state',
}));

function BillingAddressUpdateModal({
  billingAddress,
  handleCloseModal,
  handleOnSuccess,
}) {
  const { mutateAsync: mutateAddress } = useMutateUserBillingAddress();

  const [localBillingAddress, setBillingAddress] = useState(billingAddress);

  const [isSubmitting, toggleSubmitting] = useBooleanInput(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [submitError, setSubmitError] = useState('');

  function handleAddressFieldChange(e) {
    const name = e.name || e.target.name;
    const value = e.value || e.target.value;

    setBillingAddress(state => ({ ...state, [name]: value }));
    setFieldErrors(state => ({ ...state, [name]: '' }));
  }

  function validateFields() {
    const errors = {};
    let isValid = true;

    Object.keys(localBillingAddress).forEach(key => {
      if (!localBillingAddress[key] && key !== 'street_2') {
        isValid = false;
        errors[key] = 'This field is required';
      }

      if (key === 'zipcode' && localBillingAddress[key].length < 5) {
        isValid = false;
        errors[key] = 'Please enter a valid zipcode';
      }
    });

    setFieldErrors(errors);
    return isValid;
  }

  function handleSubmitBilling(e) {
    e.preventDefault();

    if (!validateFields()) return;

    toggleSubmitting();
    setFieldErrors({});

    let error = '';

    postBillingAddressValidate(localBillingAddress)
      .catch(() => {
        error =
          'An error occurred while checking your billing address. Please check that the fields are valid and try again';
        throw new Error();
      })
      .then(() =>
        mutateAddress({
          address: localBillingAddress,
        })
      )
      .then(() => {
        toggleSubmitting();
        handleOnSuccess();
      })
      .catch(() => {
        toggleSubmitting();

        setSubmitError(
          error ||
            'An error happened while updating the billing address. Please try again or reach out to support'
        );
      });
  }

  return (
    <Modal
      onClose={handleCloseModal}
      classes={{
        container: modalStyles.modalContainer,
        content: modalStyles.modal,
      }}
      aria-label='Edit billing address'
    >
      <h3>Edit billing address</h3>
      <h5>Edit billing address</h5>

      <form
        className={classNames(styles.form, modalStyles.modalBody)}
        onSubmit={handleSubmitBilling}
      >
        <TextField
          id='street_1'
          name='street_1'
          label='Billing address'
          value={localBillingAddress?.street_1 || ''}
          onChange={handleAddressFieldChange}
          error={{ errorMessage: fieldErrors.street_1 }}
        />

        <TextField
          id='street_2'
          name='street_2'
          label='Billing address line 2 (Optional)'
          value={localBillingAddress?.street_2 || ''}
          onChange={handleAddressFieldChange}
        />

        <TextField
          id='city'
          name='city'
          label='City'
          value={localBillingAddress?.city || ''}
          onChange={handleAddressFieldChange}
          error={{ errorMessage: fieldErrors.city }}
        />

        {localBillingAddress?.country === 'US' ? (
          <Select
            options={MAPPED_STATES}
            onChange={handleAddressFieldChange}
            label='State'
            placeholder='State'
            defaultValue={{
              label: localBillingAddress?.state,
            }}
            classes={{ root: classNames(styles.halfWidth, styles.select) }}
            menuPosition='fixed'
            error={{ errorMessage: fieldErrors.state }}
          />
        ) : (
          <TextField
            id='state'
            name='state'
            label='State'
            value={localBillingAddress?.state}
            classes={{ container: styles.halfWidth }}
            onChange={handleAddressFieldChange}
            error={{ errorMessage: fieldErrors.state }}
          />
        )}

        <TextField
          id='zipcode'
          name='zipcode'
          label='Zip code'
          value={localBillingAddress?.zipcode || ''}
          onChange={handleAddressFieldChange}
          classes={{ container: styles.halfWidth }}
          error={{ errorMessage: fieldErrors.zipcode }}
        />

        <Select
          options={COUNTRY_OPTIONS}
          onChange={e =>
            handleAddressFieldChange({
              value: e.value,
              name: 'country',
            })
          }
          label='Country'
          placeholder='Country'
          defaultValue={{
            label:
              COUNTRY_OPTIONS.find(
                option => option.name === localBillingAddress?.country
              )?.label || COUNTRY_OPTIONS[0].label,
          }}
          menuPosition='fixed'
          classes={{ root: styles.select }}
          error={{ errorMessage: fieldErrors.country }}
        />

        {submitError ? (
          <LegalBody className={styles.error}>{submitError}</LegalBody>
        ) : null}

        <LoaderButton
          type='submit'
          color='primary'
          isLoading={isSubmitting}
          classes={{ root: modalStyles.modalButton }}
        >
          Update billing address
        </LoaderButton>
      </form>

      <button
        onClick={handleCloseModal}
        className={classNames(
          modalStyles.modalButton,
          modalStyles.closeModalButton,
          ButtonStyles.baseButton
        )}
      >
        Cancel
      </button>
    </Modal>
  );
}

BillingAddressUpdateModal.propTypes = {
  billingAddress: PropTypes.shape({
    street_1: PropTypes.string.isRequired,
    street_2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
  }).isRequired,

  handleCloseModal: PropTypes.func.isRequired,
  handleOnSuccess: PropTypes.func.isRequired,
};

export default BillingAddressUpdateModal;
