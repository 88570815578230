import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { analyticsTrack, errorBannerShow } from '@pumpkincare/analytics';
import { getActivePlan } from '@pumpkincare/plans';
import {
  DOG,
  formatCoverageStartDate,
  formatDate,
  useBooleanInput,
  useTargetState,
} from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  Checkbox,
  CloseIcon,
  ErrorListBanner,
  FileUpload,
  LegalBody,
  LoaderButton,
  Radio,
  RadioGroup,
  SimpleHeader,
  TextArea,
  TextField,
  ToggleButton,
  Typography,
} from '@pumpkincare/shared/ui';
import { getUserPets, useUserVets, useUssr } from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import { uploadFile } from '../../../user';
import { validateClaimSubmissionFields } from '../../utils';
import LegalTextModal from './legal-text-modal';
import useSubmitClaim from './use-submit-claim';

import styles from './claims-submission.css';

function ClaimsSubmission() {
  const { allowMultiVetsPerPet, claimsUpdates, clientDisableVetClaims } = useFlags();

  const dispatch = useDispatch();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const queryPetId = urlSearchParams.get('pet_id');

  const { data: userData } = useUssr();
  const petsData = getUserPets(userData);
  const { data: vetsData, isFetched: isVetsFetched } = useUserVets();
  const { status, submit } = useSubmitClaim();

  const [claimType, setClaimType] = useState('claim');
  const [isInsuranceAccident, setIsInsuranceAccident] = useBooleanInput(false);
  const [isInsuranceIllness, setIsInsuranceIllness] = useBooleanInput(false);
  const [isPreventiveEssentials, setIsPreventiveEssentials] = useBooleanInput(false);
  const [selectedPetId, setSelectedPetId] = useState(null);
  const [selectedVetId, setSelectedVetId] = useState(null);
  const [petVets, setPetVets] = useState([]);
  const [selectedReimbursement, setReimbursement] = useState('me');
  const [areTermsAccepted, setAreTermsAccepted] = useBooleanInput(false);
  const [isModalOpen, toggleIsModalOpen] = useBooleanInput(false);
  const [diagnosisStory, setDiagnosisStory] = useTargetState('');
  const [dateList, setDateList] = useState([
    {
      dateOfVisit: null,
    },
  ]);
  const [totalAmountList, setTotalAmountList] = useState([
    {
      totalAmountClaimed: '',
    },
  ]);
  const [lossDate, setLossDate] = useState('');
  const [hasActivePlan, setHasActivePlan] = useState(false);
  const [claimFileList, setClaimFileList] = useState([]);
  const [medicalRecordsFileList, setMedicalRecordsFileList] = useState([]);
  const [errors, setErrors] = useState({});
  const [isClaimFileUploading, setIsClaimFileUploading] = useState(false);
  const [isMedicalRecordUploading, setIsMedicalRecordUploading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isMultiVet, setMultiVet] = useState();
  const [isOngoing, setIsOngoing] = useState();
  const isInsurance = isInsuranceAccident || isInsuranceIllness;

  const redAsterisk = <span className={styles.asterisk}>*</span>;
  const toggleButtonClasses = {
    root: styles.toggleButton,
    img: styles.toggleButtonImage,
    selected: styles.toggleSelected,
  };

  const selectedPet = selectedPetId
    ? petsData.find(pet => pet.id === selectedPetId)
    : {};
  const validPolicy =
    (selectedPetId &&
      selectedPet.policies.find(
        policy =>
          !!policy.policy_illness_coverage_start_date &&
          !!policy.policy_accident_coverage_start_date
      )) ||
    {};

  const {
    policy_illness_coverage_start_date: policyIllnessCoverageStartDate,
    policy_accident_coverage_start_date: policyAccidentCoverageStartDate,
  } = validPolicy;

  const isModelLaw =
    policyAccidentCoverageStartDate !== policyIllnessCoverageStartDate;

  const sectionTitleCn = classNames(Typography.h4, styles.title);

  function addAdditionalDateAndAmount() {
    setTotalAmountList([
      ...totalAmountList,
      {
        totalAmountClaimed: '',
      },
    ]);

    setDateList([
      ...dateList,
      {
        dateOfVisit: null,
      },
    ]);
  }

  function clearNestedError(type, index) {
    setErrors(errors => {
      if (errors[type]) {
        errors[type].messages[index] = '';
      }
      return errors;
    });
  }

  function handleTextFieldKeyUp(e) {
    if (!e.ctrlKey && !e.metaKey && (e.keyCode == 32 || e.keyCode > 46)) {
      e.target.value = formatDate(e.target.value);
    }
  }

  function removeDateAndAmount(index) {
    setDateList(dateList.filter((date, aux) => aux !== index));
    setTotalAmountList(totalAmountList.filter((total, aux) => aux !== index));
  }

  function handleDateOfVisitChange(date, index) {
    const list = [...dateList];
    const obj = { ...list[index] };
    obj.dateOfVisit = date.target.value;
    list[index] = obj;

    setDateList(list);
  }

  function handleTotalAmountClaimed(index, e) {
    const list = [...totalAmountList];
    const obj = { ...list[index] };
    obj.totalAmountClaimed = e.target.value;
    list[index] = obj;

    setTotalAmountList(list);
  }

  const handleSelectPet = useCallback(
    pet => {
      setSelectedPetId(pet.id);
      const hasActivePlan = !!getActivePlan(pet.plans);
      setHasActivePlan(hasActivePlan);

      if (!hasActivePlan) {
        setIsPreventiveEssentials(false);
      }
    },
    [setIsPreventiveEssentials]
  );

  function handleSelectVet(vet) {
    setSelectedVetId(vet.id);
  }

  function handleSubmitClaim() {
    setHasSubmitted(true);

    const { errors } = validateClaimSubmissionFields(
      {
        fileList: claimFileList,
        dateList,
        injuryDate: lossDate,
        totalAmountList,
        vetVisitReason: diagnosisStory,
        isPreventiveEssentials,
        isInsuranceIllness,
        isInsuranceAccident,
        medicalRecordsFileList,
        selectedVet: selectedVetId,
        isOngoing,
        isMultiVet,
        selectedPet,
      },
      {
        shouldValidateMultiVetAndOngoing: claimsUpdates,
        shouldValidateClinic: allowMultiVetsPerPet && petVets.length,
      }
    );

    if (Object.keys(errors).length) {
      setErrors(errors);

      window.scrollTo(0, 0);
    } else {
      setErrors({});
      submit({
        claimedAmounts: totalAmountList,
        visits: dateList,
        claimAttachments: claimFileList,
        isAccident: isInsuranceAccident,
        isEstimate: claimType === 'estimate',
        isIllness: isInsuranceIllness,
        lossDate,
        hasPrevent: isPreventiveEssentials,
        medicalRecordsFileList,
        petId: selectedPetId,
        sendReimbursementToVet: selectedReimbursement === 'my_vet',
        diagnosisStory,
      });
    }
  }

  function handleBannerError(banner) {
    analyticsTrack(errorBannerShow(banner));
  }

  function deleteErrorType(type) {
    setErrors(errors => {
      delete errors[type];
      return errors;
    });
  }

  useEffect(() => {
    if (petsData.length) {
      const pet =
        (queryPetId && petsData.find(pet => pet.id === queryPetId)) || petsData[0];
      handleSelectPet(pet);
    }
  }, [handleSelectPet, petsData, queryPetId]);

  useEffect(() => {
    if (isVetsFetched) {
      setPetVets(
        vetsData
          .filter(vet => vet.permissions.can_view_data)
          .filter(vet => vet.pets?.find(pet => pet.id === selectedPetId))
      );
    }
  }, [isVetsFetched, selectedPetId, vetsData]);

  return (
    <>
      <SimpleHeader returnLink={Paths.Claims} />

      {hasSubmitted && Object.keys(errors).length > 0 ? (
        <ErrorListBanner
          errors={Object.values(errors).map(({ title }) => title)}
          onClose={() => setErrors({})}
          onError={handleBannerError}
        />
      ) : null}

      <div className={styles.content}>
        <h1 className={styles.header}>File a Claim</h1>

        <Body1>{redAsterisk} = required field</Body1>

        <LegalBody className={styles.notes}>
          Insurance claims outside of coverage start dates will not be accepted.
        </LegalBody>

        <h4 className={sectionTitleCn}>1. Upload Itemized Invoice {redAsterisk}</h4>

        <Body1 className={styles.subtitle}>
          <span className={styles.subTitleColor}>
            Please include all pages of your itemized invoice.
          </span>
        </Body1>

        <FileUpload
          files={claimFileList}
          classes={{ root: styles.fileUpload }}
          onFileListChange={setClaimFileList}
          onError={message => {
            if (message) {
              setErrors(errors => ({
                ...errors,
                itemizedInvoice: { title: 'Itemized Invoice', message },
              }));
            }
          }}
          error={errors.itemizedInvoice?.message || ''}
          onIsUploadingChange={setIsClaimFileUploading}
          onUpload={(file, rawFile) => {
            deleteErrorType('itemizedInvoice');
            return dispatch(uploadFile(file, rawFile, 'claim-attachment'));
          }}
        />

        <h4 className={sectionTitleCn}>
          2. Upload Medical Records -{' '}
          <span className={styles.medRecordsColor}>Optional</span>
        </h4>

        <Body1 className={styles.subtitle}>
          <span className={styles.subTitleColor}>
            Help us process your future claims faster! Upload photos, scanned or
            digital copies of all medical history reports received from your vet.
          </span>
        </Body1>

        <FileUpload
          classes={{ root: styles.fileUpload }}
          files={medicalRecordsFileList}
          onFileListChange={setMedicalRecordsFileList}
          onIsUploadingChange={setIsMedicalRecordUploading}
          onUpload={(file, rawFile) =>
            dispatch(uploadFile(file, rawFile, 'medical-record'))
          }
        />

        <h4 className={sectionTitleCn} style={{ marginBottom: '32px' }}>
          3. Enter Claim Details
        </h4>

        <Body2 className={styles.subtitle}>I am filing: {redAsterisk}</Body2>

        <div className={styles.gridColumns2}>
          <div className={styles.buttonContainer}>
            <ToggleButton
              classes={toggleButtonClasses}
              label='A Claim'
              selected={claimType === 'claim'}
              icon={
                claimType === 'claim'
                  ? '/assets/images/iconImages/01_illustrations_misc_paw_white.png'
                  : '/assets/images/iconImages/01_illustrations_misc_paw_blue.png'
              }
              onClick={() => setClaimType('claim')}
              data-testid='claim-wrapper'
            />
          </div>

          <div className={styles.buttonContainer}>
            <ToggleButton
              classes={toggleButtonClasses}
              label='An Estimate'
              selected={claimType === 'estimate'}
              icon={
                claimType === 'estimate'
                  ? '/assets/images/iconImages/01_illustrations_misc_paw_white.png'
                  : '/assets/images/iconImages/01_illustrations_misc_paw_blue.png'
              }
              onClick={() => setClaimType('estimate')}
              data-testid='estimate-wrapper'
            />
          </div>
        </div>

        <div className={styles.subtitle} style={{ display: 'flex' }}>
          <Body2>What Type of Claim? {redAsterisk}&nbsp;</Body2>

          <Body1 className={styles.selectAllDiv}>Select all that apply.</Body1>
        </div>

        {errors.claimType?.message ? (
          <Body1 className={styles.validationError}>
            {errors.claimType.message}
          </Body1>
        ) : null}

        <div
          className={hasActivePlan ? styles.gridColumns3 : styles.gridColumns2}
          onClick={() => deleteErrorType('claimType')}
        >
          <div className={styles.buttonContainer}>
            <ToggleButton
              classes={toggleButtonClasses}
              label='Insurance (Accident)'
              icon={'/assets/images/iconImages/nav-bar/bandaid-pink.png'}
              selected={isInsuranceAccident}
              onClick={setIsInsuranceAccident}
              data-testid='insurance-accident-wrapper'
            />
          </div>

          <div className={styles.buttonContainer}>
            <ToggleButton
              classes={toggleButtonClasses}
              label='Insurance (Illness)'
              selected={isInsuranceIllness}
              icon={'/assets/images/iconImages/nav-bar/bandaid-pink.png'}
              onClick={setIsInsuranceIllness}
              data-testid='insurance-illness-wrapper'
            />
          </div>

          {hasActivePlan ? (
            <div className={styles.buttonContainer}>
              <ToggleButton
                label='Preventive Essentials'
                selected={isPreventiveEssentials}
                icon={'/assets/images/iconImages/nav-bar/star.png'}
                classes={toggleButtonClasses}
                onClick={setIsPreventiveEssentials}
                data-testid='preventive-wrapper'
              />
            </div>
          ) : null}
        </div>

        <Body2 className={styles.subtitle}>For which pet? {redAsterisk}</Body2>

        <div className={styles.gridColumns2} style={{ marginBottom: '8px' }}>
          {petsData.map(pet => (
            <div key={pet.id} className={styles.buttonContainer}>
              <ToggleButton
                label={pet.name}
                selected={selectedPetId === pet.id}
                icon={
                  pet.species === DOG
                    ? '/assets/images/iconImages/nav-bar/ball.png'
                    : '/assets/images/iconImages/01_illustrations_misc_yarn.png'
                }
                classes={toggleButtonClasses}
                onClick={() => handleSelectPet(pet)}
                data-testid={`${pet.name
                  .replace(/\W+/g, '-')
                  .toLowerCase()}-selector-wrapper`}
              />
            </div>
          ))}
        </div>

        {policyIllnessCoverageStartDate ? (
          <LegalBody style={{ marginBottom: '40px' }}>
            {selectedPet.name}'s Coverage Dates:
            <ul className={styles.coverageDateList}>
              {!isModelLaw ? (
                <li>
                  Insurance Coverage Start Date:{' '}
                  {formatCoverageStartDate(policyIllnessCoverageStartDate).long}
                </li>
              ) : null}

              {isModelLaw && policyAccidentCoverageStartDate ? (
                <>
                  <li>
                    Accident Coverage Start Date:
                    {formatCoverageStartDate(policyAccidentCoverageStartDate).long}
                  </li>

                  <li>
                    Illness Coverage Start Date:
                    {formatCoverageStartDate(policyIllnessCoverageStartDate).long}
                  </li>
                </>
              ) : null}
            </ul>
          </LegalBody>
        ) : null}

        {claimsUpdates && isInsurance ? (
          <div className={styles.radioSection}>
            <Body2 className={styles.subtitle}>
              Did they visit more than one vet clinic for this accident/illness?{' '}
              {redAsterisk}
            </Body2>

            <RadioGroup name='isMultiVet' value={isMultiVet} onChange={setMultiVet}>
              <Radio value={true} label='Yes' />
              <Radio value={false} label='No' />
            </RadioGroup>
          </div>
        ) : null}

        {dateList.map((item, aux) => (
          <div key={aux} className={styles.gridColumnsSpaced}>
            <div>
              <TextField
                maxLength={10}
                label={<Body2>Date of visit:</Body2>}
                required
                placeholder='MM/DD/YYYY'
                classes={{ label: styles.subtitle }}
                onFocus={() => clearNestedError('dateList', aux)}
                error={{ errorMessage: errors.dateList?.messages[aux] || '' }}
                onChange={event => handleDateOfVisitChange(event, aux)}
                onKeyUp={handleTextFieldKeyUp}
                data-testid='visit-date-wrapper'
              />
            </div>

            <div style={{ position: 'relative' }}>
              <TextField
                id='outlined-basic'
                data-testid='amount-claimed-wrapper'
                error={{
                  errorMessage: errors.amount?.messages[aux],
                }}
                value={totalAmountList[aux].totalAmountClaimed}
                required
                label={<Body2>Total amount claimed:</Body2>}
                placeholder='0000.00'
                classes={{ label: styles.subtitle }}
                onFocus={() => clearNestedError('amount', aux)}
                onChange={e => handleTotalAmountClaimed(aux, e)}
                startAdornment={{ startContent: '$' }}
              />

              {aux > 0 ? (
                <button
                  onClick={() => removeDateAndAmount(aux)}
                  className={styles.removeDateClearButton}
                >
                  <CloseIcon
                    className={styles.removeDateClearIcon}
                    fontSize='small'
                  />
                </button>
              ) : null}
            </div>
          </div>
        ))}

        <LegalBody
          onClick={addAdditionalDateAndAmount}
          className={styles.addAdditionalDates}
          data-testid='add-dates-wrapper'
        >
          Add additional dates +
        </LegalBody>

        {isInsurance ? (
          <div className={styles.gridColumns2}>
            <div>
              <TextField
                maxLength={10}
                label={<Body2>When did the accident/illness first occur?</Body2>}
                required
                placeholder='MM/DD/YYYY'
                classes={{ label: styles.subtitle }}
                error={{ errorMessage: errors.dateOfIncident?.message || '' }}
                onFocus={() => deleteErrorType('dateOfIncident')}
                onChange={event => setLossDate(event.target.value)}
                onKeyUp={handleTextFieldKeyUp}
                data-testid='injury-date-wrapper'
              />
            </div>
          </div>
        ) : null}

        {claimsUpdates && isInsurance ? (
          <div className={styles.radioSection}>
            <Body2 className={styles.title}>
              Is this a new condition? {redAsterisk}
            </Body2>

            <RadioGroup name='isOngoing' value={isOngoing} onChange={setIsOngoing}>
              <Radio value={false} label='Yes' />
              <Radio value={true} label='No' />
            </RadioGroup>
          </div>
        ) : null}
        {!clientDisableVetClaims ? (
          <>
            <Body2 className={styles.title}>
              Who should get reimbursed? {redAsterisk}
            </Body2>

            <div className={styles.gridColumnsNoWrap}>
              <div className={styles.buttonContainer}>
                <ToggleButton
                  classes={toggleButtonClasses}
                  icon='/assets/images/iconImages/01_illustrations_misc_heart.png'
                  label='Me'
                  selected={selectedReimbursement === 'me'}
                  onClick={() => setReimbursement('me')}
                  data-testid='me-wrapper'
                />
              </div>

              <div className={styles.buttonContainer}>
                <ToggleButton
                  classes={toggleButtonClasses}
                  icon='/assets/images/iconImages/nav-bar/medical-bag.png'
                  label='My Vet'
                  selected={selectedReimbursement === 'my_vet'}
                  onClick={() => setReimbursement('my_vet')}
                  data-testid='my-vet-wrapper'
                />
              </div>
            </div>
          </>
        ) : null}

        {allowMultiVetsPerPet && petVets.length > 0 ? (
          <>
            <Body2 className={styles.title}>
              Which vet clinic did your fur baby visit? {redAsterisk}
            </Body2>

            <div className={styles.gridColumns2}>
              {petVets.map(vet => (
                <div key={vet.id} className={styles.buttonContainer}>
                  <ToggleButton
                    label={vet.display_name}
                    selected={selectedVetId === vet.id}
                    icon='/assets/images/iconImages/nav-bar/medical-bag.png'
                    classes={toggleButtonClasses}
                    onClick={() => handleSelectVet(vet)}
                    data-testid={`${vet.display_name
                      .replace(/\W+/g, '-')
                      .toLowerCase()}-selector-wrapper`}
                  />
                </div>
              ))}

              <div key='other_vet' className={styles.buttonContainer}>
                <ToggleButton
                  label='Other'
                  selected={selectedVetId === 'other_vet'}
                  icon='/assets/images/iconImages/nav-bar/medical-bag.png'
                  classes={toggleButtonClasses}
                  onClick={() => handleSelectVet({ id: 'other_vet' })}
                  data-testid={'other-selector-wrapper'}
                />
              </div>
            </div>
          </>
        ) : null}

        <Body2 className={styles.reasonVetVisitTitle}>
          What was the reason for the vet visit? {redAsterisk}
        </Body2>

        <Body1 className={styles.reasonVetVisitSubtitle}>
          Describe what happened to your pet. Please be sure to include:
          <ul>
            <li>Details around the incident or illness</li>
            <li>when the symptoms or incident started happening</li>
            <li>occurrence/diagnosis provided by your vet.</li>
          </ul>
        </Body1>
        <TextArea
          classes={{ root: styles.textArea }}
          error={{ errorMessage: errors.vetVisit?.message || '' }}
          onFocus={() => deleteErrorType('vetVisit')}
          onChange={setDiagnosisStory}
          value={diagnosisStory}
          data-testid='reason-why-wrapper'
          rows={5}
        />

        <div className={styles.acceptTermsDiv}>
          <Checkbox
            label={
              <Body1>
                I have read and accept the
                <span onClick={toggleIsModalOpen} className={styles.fraudNoticeLink}>
                  declaration and fraud notice.
                </span>
              </Body1>
            }
            value='terms'
            onChange={setAreTermsAccepted}
            checked={areTermsAccepted}
            data-testid='read-accepted-checkbox'
          />
        </div>

        <div className={styles.buttonContainer}>
          <LoaderButton
            color='primary'
            disabled={
              !areTermsAccepted || isClaimFileUploading || isMedicalRecordUploading
            }
            isLoading={
              status === 'loading' ||
              isClaimFileUploading ||
              isMedicalRecordUploading
            }
            onClick={handleSubmitClaim}
            classes={{ root: styles.submissionButton }}
            data-testid='submit-button'
          >
            Submit Claim
          </LoaderButton>
        </div>
      </div>

      {isModalOpen ? <LegalTextModal handleCancel={toggleIsModalOpen} /> : null}
    </>
  );
}

export default ClaimsSubmission;
