import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { analyticsTrack, renewalsModalClose } from '@pumpkincare/analytics';
import { QF_URL } from '@pumpkincare/config';
import { hasCustomPlanDoc, isUnlimitedLimit } from '@pumpkincare/plans';
import { formatISODate } from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  CollapsiblePanel,
  LegalBody,
  Link,
  MoneyLabel,
} from '@pumpkincare/shared/ui';
import { getUserTransactionFee, useUssr } from '@pumpkincare/user';

import { getUserIsChargedAnnually } from '../../selectors';
import {
  downloadPlanCa,
  downloadPolicyCa,
  downloadUpdatedPolicyCa,
} from '../../utils';
import Prevent from '../prevent';
import RenewalModal from '../renewal-modal';

import styles from './pet-policy.css';

export function formatPolicyDocuments(policyChanges, policyDocuments) {
  const policyChangesFiltered = policyChanges.filter(
    policy => policy.document_id !== null
  );

  const mergedPolicyDocuments = [...policyChangesFiltered, ...policyDocuments];

  const sortedMergedPolicyDocuments = mergedPolicyDocuments.sort(
    (a, b) =>
      new Date(a.processed_at ? a.processed_at : a.created_at) -
      new Date(b.processed_at ? b.processed_at : b.created_at)
  );

  sortedMergedPolicyDocuments.shift();
  sortedMergedPolicyDocuments.reverse();

  return sortedMergedPolicyDocuments;
}

function PetPolicy({ configPets }) {
  const isAnnuallyCharged = useSelector(getUserIsChargedAnnually);

  const [renewalModalPet, setRenewalModalPet] = useState(null);

  const { hasRenewalModal } = useFlags();

  const { data: userData } = useUssr();
  const transactionFee = getUserTransactionFee(userData);

  function handleOnDownloadPlan(configPet) {
    const { renewalPlan } = configPet;

    if (hasCustomPlanDoc(renewalPlan.plan)) {
      downloadPlanCa();
    } else {
      downloadPlanCa(configPet.pet.species, renewalPlan.plan.version_number);
    }
  }

  function handleViewRenewalModalClick(event, pet) {
    event.stopPropagation();
    setRenewalModalPet(pet);
  }

  function handleRenewalModalClose(closeType, page, modalType) {
    setRenewalModalPet(null);

    analyticsTrack(renewalsModalClose(closeType, page, modalType));
  }
  const petCards = configPets.map(configPet => {
    const {
      id: policyId,
      policy_changes: policyChanges,
      documents: policyDocuments,
    } = configPet.policy || [];

    return (
      <CollapsiblePanel
        key={`Panel-${configPet.pet.id}`}
        classes={{
          collapseContainer: styles.collapseContainer,
          collapseButton: styles.collapseButton,
          title: styles.collapsibleTitle,
          collapseIcon: styles.collapseIcon,
          contentContainer: styles.contentContainer,
        }}
        title={
          <>
            <h3 className={styles.petName}>{`${configPet.pet.name}'s Plan`}</h3>
            {configPet.renewalPolicy ? (
              <div className={styles.renewalsContainer}>
                <img
                  className={styles.lightbulbImage}
                  src='/assets/images/illustrations/misc/lightbulb.png'
                  role='presentation'
                />
                <div>
                  <Body1 isBold className={styles.renewalTitle}>
                    {configPet.isRenewalDay ? (
                      `${configPet.pet.name}'s plan has automatically renewed!`
                    ) : (
                      <>
                        {configPet.pet.name}'s plan will automatically renew on{' '}
                        <span className={styles.renewalDate}>
                          {configPet.renewalPolicyStartDate}
                        </span>
                        .
                      </>
                    )}
                  </Body1>
                  <Body2 className={styles.renewalSubtitle}>
                    {configPet.isRenewalDay || !hasRenewalModal ? (
                      `See your updated benefits below.`
                    ) : (
                      <>
                        See your benefits before your renewal date.{' '}
                        <span
                          onClick={event =>
                            handleViewRenewalModalClick(event, configPet)
                          }
                          className={styles.renewalDetailsLink}
                        >
                          View Renewal Details
                        </span>
                      </>
                    )}
                  </Body2>
                </div>
              </div>
            ) : null}

            <LegalBody className={styles.petInfoSummaryContainer}>
              {configPet.summaryInfoText}
            </LegalBody>
            <LegalBody className={styles.petInfoSummaryContainer}>
              {configPet.summaryProductsText}
            </LegalBody>
          </>
        }
      >
        <div className={styles.pumpkinSection}>
          <div className={styles.pumpkinLeft}>
            <Body1>Pumpkin Pet Insurance</Body1>

            <div style={{ paddingTop: '10px' }}>
              <LegalBody className={styles.protectDetails}>
                Billing Period: {configPet.policyStartDate} -{' '}
                {configPet.policyEndDate}
              </LegalBody>
            </div>
          </div>

          <div className={styles.pumpkinRight}>
            <div className={styles.detailDiv}>
              <LegalBody className={styles.detailsBody1}>
                <MoneyLabel
                  value={configPet.policyAnnualDeductible}
                  displayName='Annual Deductible'
                  isInteger
                />
              </LegalBody>

              <LegalBody className={styles.detailsBody1}>
                Reimbursement Rate: {configPet.policyAnnualReimbursement}
              </LegalBody>

              <LegalBody className={styles.detailsBody1}>
                {isUnlimitedLimit(configPet.policyAnnualLimit) ? (
                  <LegalBody className={styles.detailsBody1}>
                    Annual Coverage Limit: {configPet.policyAnnualLimit}
                  </LegalBody>
                ) : (
                  <MoneyLabel
                    value={configPet.policyAnnualLimit}
                    displayName='Annual Coverage Limit'
                    isInteger
                  />
                )}
              </LegalBody>
            </div>
          </div>
        </div>

        {configPet.isActivePetPolicyAvailable ? (
          <>
            {formatPolicyDocuments(policyChanges, policyDocuments).map(item => (
              <LegalBody
                onClick={() => {
                  downloadUpdatedPolicyCa(policyId, item.document_id || item.id);
                }}
                className={styles.downloadUpdatedPolicy}
                key={item.id}
              >
                Download Updated Insurance Policy{' '}
                {formatISODate(item.processed_at || item.created_at)}
              </LegalBody>
            ))}

            <LegalBody
              onClick={() => {
                downloadPolicyCa(configPet.policy.id);
              }}
              className={styles.downloadPolicy}
            >
              Download Insurance Policy
            </LegalBody>
          </>
        ) : (
          <LegalBody className={styles.downloadPolicyEmpty}>
            Pet insurance policy not yet available. Check back soon.
          </LegalBody>
        )}

        {configPet.userHasPlan ? <Prevent pet={configPet.pet} /> : null}
      </CollapsiblePanel>
    );
  });

  return (
    <div className={styles.policyRoot}>
      {petCards}

      <div className={styles.AddPetDiv}>
        <LegalBody className={styles.addPetRedText}>
          Save 10% on a plan for each additional pet you enroll!
        </LegalBody>

        <Link href={QF_URL}>
          <button
            className={classNames(ButtonStyles.secondary, styles.addPetButton)}
          >
            + Add a Pet
          </button>
        </Link>
      </div>

      {renewalModalPet ? (
        <RenewalModal
          isAnnuallyCharged={isAnnuallyCharged}
          configPets={configPets}
          onClose={handleRenewalModalClose}
          onDownloadPolicy={downloadPolicyCa}
          onDownloadPlan={handleOnDownloadPlan}
          transactionFee={transactionFee}
        />
      ) : null}
    </div>
  );
}

export default PetPolicy;
