import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getIsLoggedIn } from '@pumpkincare/shared';

import Paths from '../../paths';
import Page from '../page';

function PrivatePage(props) {
  return getIsLoggedIn() ? (
    <Page isPrivate {...props} />
  ) : (
    <Redirect to={Paths.Login} />
  );
}

PrivatePage.propTypes = {
  isFooterVisible: PropTypes.bool,
  isNavBarVisible: PropTypes.bool,
};

export default PrivatePage;
