import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ERROR, FETCHING, INITIAL, SUCCESS } from '@pumpkincare/shared';

import createUserVet from '../thunks/create-user-vet';
import fetchUserVets from '../thunks/fetch-user-vets';
import updateUserVet from '../thunks/update-user-vet';

const PENDING = 'pending';

// @deprecated
// we're gradually moving all this functionality to the lib
export default function useUserVetsLegacy(userId) {
  const dispatch = useDispatch();

  const [vets, setVets] = useState([]);
  const [statusOfUpdate, setUpdateStatus] = useState(INITIAL);
  const [statusOfPost, setPostStatus] = useState(INITIAL);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserVets(userId)).then(vets =>
        setVets(
          vets.map(vet => ({
            ...vet,
            permissions: {
              ...vet.permissions,
              needsClaimAction: vet.permissions.can_file_claim_status === PENDING,
              needsDataAction: vet.permissions.can_view_data_status === PENDING,
            },
          }))
        )
      );
    }
  }, [dispatch, userId]);

  function handleUserVetUpdate(vetId, body) {
    setUpdateStatus(FETCHING);
    return dispatch(updateUserVet(userId, vetId, body))
      .then(responseVet => {
        setUpdateStatus(SUCCESS);
        return setVets(prevVets =>
          prevVets.map(prevVet => (prevVet.id === vetId ? responseVet : prevVet))
        );
      })
      .catch(err => {
        setUpdateStatus(ERROR);
        throw err;
      });
  }

  function handleUserVetPost(vetId, body) {
    setPostStatus(FETCHING);
    return dispatch(createUserVet(userId, vetId, body))
      .then(vet => {
        setPostStatus(SUCCESS);
        return setVets(prevVets => {
          prevVets.push(vet);
          return prevVets;
        });
      })
      .catch(err => {
        setPostStatus(ERROR);
        throw err;
      });
  }

  function handleUserVetDelete(vetId) {
    setUpdateStatus(FETCHING);
    return dispatch(
      updateUserVet(userId, vetId, {
        can_file_claim: false,
        can_view_data: false,
      })
    )
      .then(() => {
        setUpdateStatus(SUCCESS);
        return setVets(prevVets => prevVets.filter(prevVet => prevVet.id !== vetId));
      })
      .catch(err => {
        setUpdateStatus(ERROR);
        throw err;
      });
  }

  return {
    vets,
    statusOfPost,
    statusOfUpdate,
    handleUserVetDelete,
    handleUserVetPost,
    handleUserVetUpdate,
  };
}
